export const COMPANY_TRANSLATIONS_FR = {
  nkjm: {
    frontPageTopContent: `
        <div style="position:absolute;top: 50px; left: 50px;"><img src="https://assets.adnuntius.com/ZxrPk71X0QRHz9VjoaGyubjZyPRrXl8CMd0SZJMwy7c.png"></div>
    `,
    frontPage: `
      <h2 style="font-size: 35px">La plus grande place de marché pour accéder aux média Français</h2>
      <p><img src="https://www.mediasquare.fr/wp-content/themes/mediasquare/assets/point-gris-little.svg" style="margin: 10px 0;"></p>
      <p style="font-size: 15px; color: #555;">Media Square est une société créée par des éditeurs Français pour faciliter la diffusion de campagnes publicitaires sur leurs sites Internet et Mobile.
Notre mission est de rendre la publicité digitale dans les Medias simple, accessible et efficace.</p>
    `
  },
  l16s: {
    frontPageTopContent: `
        <div style="position:absolute;top: 50px; left: 50px;"><img style="width: 200px" src="https://assets.adnuntius.com/cfadbe/rgx808j1wklvt51qj3kjcxgw1sp6dwzjfx7bml78jsvkg75dg8.png"></div>
    `,
    frontPage: `
      <h2 style="font-size: 35px">Le Guichet Unique des Médias Suisses</h2>
      <p><img src="https://www.mediasquare.fr/wp-content/themes/mediasquare/assets/point-gris-little.svg" style="margin: 10px 0;"></p>
      <p style="font-size: 15px; color: #555;">One ID vous donne accès au meilleur des médias suisses sur une seule et même place de marché publicitaire numérique.</p>
    `
  }
};
