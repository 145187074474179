/**
 * Copyright © 2023 Adnuntius AS.
 */

export const TRANSLATIONS_FR = {
  adn: {
    copyright: 'Copyright © 2023 Adnuntius AS',
    releaseId: 'Release ID: {{id}}',
    poweredBy: 'Powered by Adnuntius AS',
  },
  grammar: {
    listSeparator: ', ',
    titleSeparator: ': ',
    booleanOr: 'OU',
  },
  general: {
    new: 'Nouveau',
    saveSuccess: 'Vos mises à jour ont été enregistrées avec succès.',
    timezone: 'Toutes les dates et heures sont en fuseau horaire de {{timezone}}.'
  },
  button: {
    upload: {
      //asset: 'Upload',
      asset: 'télécharger',
      //drop: 'Drop a file here or use the upload button'
      drop: 'Déplacez un fichier ici ou utilisez le bouton télécharger',
    },
    cancel: 'Annuler',
    //archive: 'Archive',
    archive: 'Archiver',
    //activate: 'Activate',
    activate: 'Activer',
    //applyCustomRange: 'Apply custom range',
    applyCustomRange: 'Appliquer le champ personnalisé',
    //clear: 'Clear',
    clear: 'Effacer',
    //close: 'Close',
    close: 'Fermer',
    //newCampaign: 'Create New Campaign',
    newCampaign: 'Créer une nouvelle campagne',
    //save: 'Save',
    save: 'Sauvegarder',
    //undo: 'Undo',
    undo: 'Annuler',
    //today: 'Today',
    today: "Aujourd'hui",
  },
  locale: {
    ar: 'Arabe - عربى',
    ar_JO: 'Arabe (Jordanien) - العربية (الأردن)',
    ar_EG: 'Arabe (Egyptien) - العربية (مصر)',
    ar_AE: 'Arabe (UAE) - العربية (الإمارات العربية المتحدة)',
    my: 'Birman',
    en: 'Anglais',
    en_AU: 'Anglais (Australie)',
    en_CA: 'Anglais (Canada)',
    en_US: 'Anglais (USA)',
    en_GB: 'Anglais (Grande Bretagne)',
    es: 'Espagnol',
    es_ES: 'Espagnol (Espagne)',
    es_AR: 'Espagnol (Argentine)',
    et: 'Estonien - Estonie',
    fi: 'Finlandais',
    id: 'Indonesien',
    is: 'Islandais',
    de: 'Allemand',
    de_DE: 'Allemand (Allemagne)',
    de_AT: 'Allemand (Autriche)',
    de_CH: 'Allemand (Suisse)',
    nl: 'Néerlandais',
    nl_BE: 'Néerlandais (Belgique)',
    nl_NL: 'Néerlandais (Pays Bas)',
    no: 'Norvégien',
    sv: 'Suédois',
    sv_SE: 'Suédois (Suède)',
    sv_FI: 'Sudéois (Finlande)',
    pl: 'Polonais',
    pt: 'Portugais',
    pt_BR: 'Portugais (Brésil)',
    pt_PT: 'Portugais (Portugal)',
    ru: 'Russe',
    el: 'Grec',
    fr: 'français',
    da: 'Danois',
    th: 'Thailandais',
  },
  language: {
    text: 'Langue:',
  },
  campaign: {
    //archivedHeading: 'Archived Campaigns',
    archivedHeading: 'Campagnes archivées',
    //archivedNoResults: 'No archived campaigns found.',
    archivedNoResults: 'Pas de campagne archivée',
    //archivedShowLink: 'Show archived campaigns',
    archivedShowLink: 'Montrer les campagnes archivées',
    adRejected: {
      budgetUnchanged: 'Budget inchangé',
      productUnchanged: 'Produit inchangé',
      explanationGeneral: 'Explication générale',
      //explanation: 'Adjust your ad and resubmit your campaign for review to have it deliver your budgeted impressions.',
      explanation:
        'Ajustez votre annonce et soumettez à nouveau votre campagne pour examen afin qu\'elle respecte votre budget.',
      ADULT_CONTENT: {
        //name: 'Adult Content',
        name: 'Contenu Pornographique',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains adult-oriented content.'
        explanation:
          'La publicité a été rejeté car elle contient ou mène vers une page qui contient des contenus pornographiques.',
      },
      BLANK_CONTENT: {
        //name: 'Blank Content',
        name: 'Pas de contenu',
        //explanation: 'The ad was rejected because it renders as blank. Please ensure that the required content have been provided correctly.'
        explanation:
          "La publicité a été rejetée car elle ne comprend pas de contenu. Assurez-vous d'avoir fourni correctement votre contenu",
      },
      MALFORMED_CLICK_THROUGH: {
        //name: 'Malformed Destination URL',
        name: 'Url de destination tronquée',
        //explanation: 'The ad was rejected because the click destination URL does not function properly.'
        explanation: "La publicité a été rejetée car l'adresse de destination est tronquée",
      },
      DRUG_RELATED_CONTENT: {
        //name: 'Drug-Related Content',
        name: 'Contenu lié à la drogue',
        //explanation: 'The ad was rejected because it or its landing page promotes drug-related content or products. This may include tobacco, alcohol, pharmaceutical or other legal or illegal drugs.'
        explanation:
          'La publicité a été rejetée car elle contient ou promeut des contenus liés à la drogue. Ces contenus peuvent comprendre le tabac, lalcool, des produits pharmaceutiques interdits.',
      },
      WEAPON_RELATED_CONTENT: {
        //name: 'Weapon-Related Content',
        name: 'Contenu lié aux armes',
        //explanation: 'The ad was rejected because it or its landing page promotes weapons or weapon-related content. This can include content that promotes dangerous knives, guns, gun parts and other related content.'
        explanation:
          'La publicité a été rejetée car elle contient ou promeut des armes ou du contenu lié aux armes.Ce contenu interdit peut comprendre les armes blanches ou les armes à feu ou autres contenus liés',
      },
      HATE_SPEECH_CONTENT: {
        //name: 'Hate-Speech Content',
        name: 'Contenu haineux',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains hate speech content. This may include content promoting violence, harassment, racism, hatred and intolerance.'
        explanation: 'Cette publicité a été rejetée car elle comprend ou mène vers des contenus haineux',
      },
      LANDING_PAGE_REJECTED: {
        //name: 'Landing Page Rejected',
        name: 'Page de destination rejetée',
        //explanation: 'The ad was rejected because the landing page was low quality or difficult to interact with. This can include pages that disable browser navigation functions, include low value content, or lead to an email address or file, such as image, audio, video, or document, that requires an additional application to open.'
        explanation:
          'La publicité a été rejetée car la page de destination est de très basse qualité ou difficilement accessible. Ceci peut comprendre des pages qui refusent des fonctions de navigation, comprennent du contenu de très basse qualité, ou de sections qui nécessitent une application complémentaire à ouvrir.',
      },
      OFFENSIVE_CONTENT: {
        //name: 'Offensive Content',
        name: 'Contenu offensant',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains offensive or inappropriate content. Ensure that your ads and landing pages only show family safe content.',
        explanation:
          'La publicité a été rejetée car elle contient ou mène vers une page qui contient des contenus offensants ou inappropriés. Assurez-vous que vos publicités et pages de destination comprennet uniquement du contenu approprié pour les familles',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        //name: 'Unacceptable Visual Effects',
        name: 'Effets visuels non acceptés',
        //explanation: 'The ad was rejected because it uses unacceptable visual effects. This includes animated GIFs with a frame rate higher than 5 frames per second.'
        explanation:
          'La publicité a été rejetée car elle comprend des effets visuels non acceptés. Ceci peut comprendre les GIFs animés avec un taux de rotation supérieur à 5 par seconde',
      },
      DECEPTIVE_CONTENT: {
        //name: 'Deceptive Content',
        name: 'Contenu décevant',
        //explanation: 'The ad was rejected because it contains misleading claims or information. Examples include ads that make scientifically impossible claims, offer guaranteed results without a refund policy, or show testimonials claiming specific results without a link to third-party verification. Ads may also be rejected if the creative is designed to look like software download buttons, official system warnings, or webpage features like menus, search results, or other site content.'
        explanation: 'La publicité a été rejetée car elle présente un contenu erroné ou décevant.',
      },
      GAMBLING_CONTENT: {
        //name: 'Gambling Content',
        name: 'Contenu de paris interdit',
        //explanation: 'The ad was rejected because it contains gambling-related content.'
        explanation: 'La publicité a été rejetée car elle contient des contenus liés à des paris non autorisés ',
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        //name: 'Prohibited Election-Related Content',
        name: 'Contenu interdit lié aux élections',
        //explanation: 'The ad is rejected because it contains prohibited content related to government elections or currently elected government officeholders.'
        explanation:
          'La publicité a été rejetée car elle comprend des contenus interdits liés aux élections ou à des officiers gouvernementaux',
      },
      SHOCKING_CONTENT: {
        //name: 'Shocking Content',
        name: 'Contenu choquant',
        //explanation: 'The ad was rejected because it contains shocking content such as profane language, gruesome or disgusting imagery, or promotions that are designed to shock or scare.'
        explanation:
          'La publicité a été rejetée car elle comprend des contenus susceptibles de choquer les utilisateurs.',
      },
      MALWARE_CONTENT: {
        //name: 'Malware Content',
        name: 'Malwares et agents viraux',
        //explanation: 'The ad was rejected because it or its landing page contains or leads to unacceptable content such as malware.'
        explanation:
          'La publicité a été rejetée car elle contient ou mène vers des contenus comprenant des malwares ou des virus informatiques.',
      },
    },
    anon: {
      signInText: 'Créez une campagne ici!',
      heading: 'Créez votre campagne ici',
      beforeText: 'Les détails de votre campagne sont enregistrés sur votre appareil actuel. Avant que votre campagne puisse être diffusée et accessible à partir dautres appareils',
      linkText: ' créez un compte et connectez-vous',
      postText: '.',
      savingTitle: 'Nous sommes en train d\'enregistrer votre campagne dans notre base de données.',
      savingHtml: 'Votre campagne devrait être disponible sous peu. <span class="fa fa-cog fa-spin"></span>',
      saveTitle: 'Votre campagne a été enregistrée dans notre base de données.',
      saveWithMinorError: 'Nous avons rencontré quelques problèmes en essayant d\'enregistrer toutes les données dans notre base, mais maintenant que vous êtes connecté, cela ne devrait plus poser de problème pour la suite.',
      saveCampaignLinkPrefix: 'Jetez un œil à ',
      saveCampaignLinkText: 'votre campagne enregistrée et soumettez-la pour examen.',
      saveCampaignLinkPostfix: '.',
      failSaveTitle: 'Votre campagne n\'a pas pu être enregistrée dans notre base de données.',
      failSaveCampaignLinkPrefix: '',
      failSaveCampaignLinkText: 'Créez une campagne directement avec notre base de données.',
      failSaveCampaignLinkPostfix: ' maintenant que vous êtes connecté.',
      username: 'Anonyme',
      signUp: 'S\'inscrire'
    },
    layout: {
      //title: 'Select an Ad Design',
      title: 'Sélectionnez un module graphique',
      //missing: 'The layout you selected previously is not available for the current advertising option.',
      missing: "Le module que vous avez sélectionné n'est pas disponible pour l'option publicitaire choisie.",
      //missingPreview: "A valid layout needs to be selected before a creative can be previewed."
      missingPreview: 'Un module design valide doit être sélectionné avant que vous puissiez le prévisualiser.',
    },
    device: {
      title: 'Les appareils sur lesquels vous souhaitez que votre publicité soit diffusée',
      descriptionPrefix: 'Les appareils disponibles sont les suivants',
      instructions: 'Sélectionnez les appareils',
      any: 'Tous',
    },
    domainName: {
      title: 'Les sites sur lesquels vous souhaitez que votre publicité apparaisse',
      descriptionPrefix: 'Les sites disponibles sont les suivants',
      instructions: 'Sélectionnez les sites',
      any: 'Tous',
    },
    location: {
      title: 'La zone géographique de votre campagne',
      descriptionPrefix: 'Les zones géographiques disponibles sont les suivantes',
      instructions: 'Sélection votre zone géographique',
      any: 'Toutes',
    },
    userSegment: {
      title: 'Les utilisateurs que vous souhaitez cibler',
      descriptionPrefix: "Les types d'utilisateurs sont les suivants",
      instructions: "Sélectionnez vos types d'utilisateurs",
      any: 'Tous',
    },
    product: {
      //title: 'Select an Advertising Option',
      title: 'Le type de publicité que vous souhaitez diffuser',
      //adDesignChoice: '<strong>{{count}} ad designs</strong> available at these sizes:',
      adDesignChoice: '<strong>{{count}} Formats publicitaires</strong> disponibles en différentes tailles:',
      //cpm: '<strong>{{value}}</strong> per thousand impressions',
      cpm: '<strong>{{value}}</strong> par visite sur votre site',
      cpc: '<strong>{{value}}</strong> par cliquez',
      //minSpend: '<strong>{{value}}</strong> minimum spend'
      minSpend: "<strong>{{value}}</strong> d'investissement publicitaire minimum",
      maxSpend: '<strong>{{value}}</strong> dépenses maximales',
    },
    ad: {
      //title: 'Design Your Ad',
      title: 'Concevez votre publicité',
      //defaultName: 'Creative for {{campaignName}}',
      defaultName: 'Création publicitaire pour {{campaignName}}',
      //layoutFirst: 'Select an ad design before entering your ad\'s details.',
      layoutFirst: 'Sélectionnez un format publicitaire avant de renseigner vos contenus',
      //deletedAsset: 'This component references a deleted image. Upload a new one.',
      deletedAsset: "Pas d'image. Téléchargez une nouvelle image",
      imageHint: '{{mediaTypes}}, Max: {{maxSize}}kb',
      imageHintOptional: '{{mediaTypes}}, Max: {{maxSize}}kb (Optional)',
      //adSizeTitle: 'Ad Size',
      adSizeTitle: 'Taille de votre publicité',
      create: 'Choisissez une taille pour votre publicité',
      add: 'Ajouter des éléments publicitaires',
      count: 'Eléments publicitaires <strong>#{{count}}</strong>',
      maximum: 'Un maximum de 3 éléments peuvent être ajoutés',
      //choiceOptional: '(No choice)',
      choiceOptional: '(Aucun choix)',
      //runningPreviewTitle: 'Your {{width}}&times;{{height}} Advertisement',
      runningPreviewTitle: 'Prévisualisez votre publicité {{width}}&times;{{height}}',
      remove: 'Annuler',
      //previewTitle: 'Ad Preview',
      previewTitle: 'Prévisualisation de votre publicité',
      //border: 'Show border on advertisement',
      border: 'Ajouter un cadre à la publicité',
      //previewWithExample: 'This ad preview includes example data for demonstration purposes.',
      previewWithExample: "Cette prévisualisation comprend quelques données uniquement pour l'exemple",
      //textCount: 'count: {{count}}',
      textCount: 'Limite de caractères: {{count}}',
      textHintChars: '{{min}}&ndash;{{max}} caractères',
      textHintCharsOptional: '{{min}}&ndash;{{max}} caractères (Optionnel)',
      urlHint: 'URL',
      urlHintOptional: 'URL (Optionnel)',
    },
    addNewCampaign: `
    <h3>Créer une nouvelle campagne publicitaire</h3>
    <p>Trouvez et choisissez les éléments publicitaires.</p>
    <p>Créez de nouvelles campagnes publicitaires avec le bouton ci-dessus.</p>
    <p>Les campagnes déjà créées sont accessibles ici.</p>
  `,
    //campaign: 'Campaign',
    campaign: 'Campagne',
    //campaignPlural: 'Campaigns',
    campaignPlural: 'Campagnes',
    copy: {
      //copyText: "A copy of this campaign was successfully created.",
      copyText: 'Une copie de cette campagne a été effectée avec succès',
      //copyTitle: "Campaign Copied",
      copyTitle: 'Copie de campagne effectuée',
      //copyPartialText: "This campaign has been copied, but not all of the advertising material could be copied along with it.",
      copyPartialText:
        "Cette campagne a été copiée, mais quelques éléments de la publicité n'ont pas pu être reproduits",
      //copyPartialTitle: "Campaign Copied but not All Advertising Material"
      copyPartialTitle: 'Campagne copiée, mais sans la totalité des éléments publicitaires',
    },
    filter: {
      message: '{{option}}:',
    },
    //copyAfterwards: 'Go to new campaign after copy',
    copyAfterwards: 'Allez vers nouvelle campagne après avoir copié',
    //defaultName: "{{productName}} Campaign",
    defaultName: 'Campagne {{productName}}',
    //detailsTitle: 'Specify Your Campaign Details',
    detailsTitle: 'Renseignez vos détails de campagne',
    //draftedHeading: 'Drafted Campaigns',
    draftedHeading: 'Campagnes en cours de création',
    //draftedNoResults: 'No drafted campaigns found.',
    draftedNoResults: 'Pas de campagne en cours de création.',
    //endedHeading: 'Ended Campaigns',
    endedHeading: 'Campagnes terminées',
    //endedNoResults: 'No ended campaigns found.',
    endedNoResults: 'Pas de campagne terminée.',
    //expectedImpressions: 'Corresponds to about {{imps}} impressions',
    expectedImpressions: 'Vous pouvez prétendre à environ {{imps}} affichages',
    expectedClicks: 'Vous pouvez prétendre à environ {{clicks}} clics',
    cpmBoost: "Un CPM supérieur {{minPrice}} augmentera les chances d'affichage pour votre campagne",
    maxedOutCampaigns:
      '<p>Aucune nouvelle campagne ne peut être créée pour le moment.</p><p>Un maximum de {{max}} de brouillons peuvent être créés.</p><p>Archivez une campagne existante ou commencez-en une à être diffusée pour pouvoir en créer une autre.</p>',
    payment: {
      //title: 'Payment',
      title: 'Paiement',
      //missing: 'No payment is associated with this campaign.',
      missing: "Il n'y a pas de détail de paiement associé à cette campagne.",
      //cancelled: 'Your reservation was cancelled and your campaign was not submitted for review.',
      cancelled: "Le paiement de votre campagne a été annulé et votre campagne n'a pas été soumise pour mise en ligne.",
      //infoComplete: 'A {{price}} payment was completed {{time}}.',
      infoComplete: 'Votre paiement de {{price}} a bien été effectué à {{time}}.',
      //infoAuthorised: 'A {{price}} payment was authorised {{time}}.',
      infoAuthorised: 'Un paiement de {{price}} a été autorisé à {{time}}.',
      //receiptLink: 'Your <a href="{{link}}" target="_blank">payment receipt is available</a>.',
      receiptLink: '<a href="{{link}}" target="_blank">Votre reçu de paiement est disponible</a>.',
      //registering: 'Registering transaction...',
      registering: 'Transaction en cours...',
      //reserveTitle: 'Reserve Payment and Submit Your Campaign for Review',
      reserveTitle: 'Effectuer le paiement et soumettez votre campagne pour revue avant mise en ligne',
      //reserveButton: 'Reserve Payment and Submit Your Campaign for Review',
      reserveButton: 'Effectuer le paiement et soumettez votre campagne pour revue avant mise en ligne',
      //reserveParagraph1: 'We will place a <strong>hold on your account</strong> for the budgeted sum of <strong>{{amount}}</strong>, which will enable your campaign to be reviewed and run.',
      reserveParagraph1:
        'Nous <strong>débiterons votre compte</strong> de le montant réservé de <strong>{{amount}}</strong>, cela permet la vérification et la livraison de la campagne.',
      //reserveParagraph2: 'Once your campaign has ended, we will take either {{amount}} from your account or the pro-rata amount based on the actual number of impressions delivered versus the expected <strong>{{imps}} impressions</strong>.',
      reserveParagraph2:
        'Si vos <strong>{{imps}} impressions</strong> budgétisées ne sont pas livrées, votre compte sera remboursé du montant restant.',
      refunded: 'Un {{price}} remboursement de {{time}} a été effectué.',
      //failure: 'We encountered a problem trying to register your transaction. Try again, and if a problem persists, please contact us.',
      failure: 'Un problème est survenu lors de la réservation. Veuillez réessayer.',
      //saveFirst: 'You will need to save your changes before proceeding with reserving payment',
      saveFirst: 'Sauvegardez vos modifications avant de procéder au paiement de la réservation',
      //waitingForRedirect: 'We are passing on the details of your transaction to {{paymentProvider}} and will redirect you there shortly.',
      waitingForRedirect:
        'Nous transmettons les détails de votre transaction à {{paymentProvider}} et vous y redirigerons sous peu.',
      prepay: {
        detailsInfo: `
          <div class="small">
            <p>Votre campagne sera diffusée après le paiement et le contenu de votre campagne examiné.</p>
            <p>Si votre campagne ne fournit pas la valeur d'impressions de votre budget, votre compte sera remboursé du montant restant. Cependant, le montant minimum de {{minimumFee}} s'applique.</p>
            <p>Après la campagne terminée, un rapport est créé et la facture est mise à disposition.</p>
            <p>Vous pourrez interrompre la diffusion de votre campagne à tout moment, mais les frais minimum de {{minimumFee}} s'appliquent.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Votre campagne sera diffusée après le paiement et le contenu de votre campagne examiné.</p>
            <p>Si votre campagne ne fournit pas la valeur d'impressions de votre budget, votre compte sera remboursé du montant restant.</p>
            <p>Après la campagne terminée, un rapport de performance et un reçu seront générés.</p>
            <p>Vous pourrez interrompre la diffusion de votre campagne à tout moment.</p>
          </div>
        `,
      },
      manual: {
        reserveTitle: 'Envoyer la campagne pour examen',
        reserveButton: 'Envoyer pour examen',
        reserveParagraph1:
          "La campagne sera vérifiée par nos soins avant d'être activée. L'examen n'a lieu que les jours ouvrables, pas le week-end ni les jours fériés.",
        reserveParagraph2:
          'À la fin de la campagne, nous émettrons une facture de <strong> {{amount}} </strong> ou proportionnellement, en fonction des affichages réellement livrés. </strong>.',
        detailsInfo: `
          <div class="small">
            <p>La campagne peut être diffusée dès que l'examen du matériel publicitaire est terminé.</p>
            <p>Une facture sera émise à la fin de la campagne. Le montant tient compte du nombre d'affichages livrés et ne dépassera pas le budget maximum. Cependant, le montant minimum de {{minimumFee}} s'applique.</p>
            <p>Un rapport de performance est créé après la fin de la campagne.</p>
            <p>La livraison peut être interrompue à tout moment, mais les frais minimum de {{minimumFee}} s'appliquent.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>La campagne peut être diffusée dès que l'examen du matériel publicitaire est terminé.</p>
            <p>Une facture sera émise à la fin de la campagne. Le montant tient compte du nombre d'affichages livrés et ne dépassera pas le budget maximum.</p>
            <p>Un rapport de performance est créé après la fin de la campagne.</p>
            <p>La livraison peut être interrompue à tout moment.</p>
          </div>
        `,
      },
      status: {
        //CREATED: 'Created',
        CREATED: 'Créé',
        //NOT_AUTHORISED: 'Not Authorised',
        NOT_AUTHORISED: 'Non autorisé',
        //AUTHORISED: 'Authorised',
        AUTHORISED: 'Autorisé',
        //COMPLETED: 'Completed',
        COMPLETED: 'Effectué',
        PAUSED: 'En pause',
        //CANCELLED: 'Cancelled'
        CANCELLED: 'Annulé',
      },
    },
    postPayment: {
      //reserveParagraph1: 'Payment for your campaign has been authorised.',
      reserveParagraph1: 'Le paiement de votre campagne a été autorisé.',
      //reserveParagraph2: 'If you\'re happy with your campaign, you can submit it for review below.',
      reserveParagraph2: 'Si vous êtes satisfait de votre campagne, vous pouvez la soumettre pour examen ci-dessous.',
      //saveFirst: 'You will need to save your changes before proceeding with submitting your campaign for review.',
      saveFirst: 'Vous devrez enregistrer vos modifications avant de soumettre votre campagne pour examen.',
      failed: 'Un problème est survenu lors de la soumission pour examen. Veuillez réessayer.',
      //submitButton: 'Submit Your Campaign for Review.'
      submitButton: 'Envoyer la campagne pour examen.',
    },
    //rejectedHeading: 'Rejected Campaigns',
    rejectedHeading: 'Campagne rejetée',
    report: {
      //title: 'Report',
      title: 'Rapport statistique',
      //ungenerated: 'A report will be available for you to download shortly.',
      ungenerated: 'Le rapport sera disponible sous peu.',
      //generated: 'A <a href="{{link}}" target="_blank">report is available for you to download</a>.'
      generated: 'Le rapport <a href="{{link}}" target="_blank">est disponible pour téléchargement</a>.',
    },
    //runningHeading: 'In-Review and Running Campaigns',
    runningHeading: "Campagnes en cours d'examen et campagnes actives",
    //runningNoResults: 'No running campaigns found.',
    runningNoResults: 'Aucune campagne active trouvée',
    running: {
      //budgetHeading: 'Budget Position',
      budgetHeading: 'Evolution de votre budget',
      //budgetPosition: '<strong>{{budgetSpent}}</strong> of your <strong>{{budget}} budget</strong> has been spent.',
      budgetPosition: '<strong>{{budgetSpent}}</strong> des <strong>{{budget}} du Budget </strong> ont été dépensés.',
      //cancelledPayment1: 'Your payment reservation has been cancelled.',
      cancelledPaymentParagraph1: 'La réservation de paiement a été annulée',
      //cancelledPayment2: 'If you still wish to proceed with your campaign, you can generate a new transaction below.',
      cancelledPaymentParagraph2:
        'Veuillez redémarrer la transaction si vous souhaitez continuer à utiliser la campagne.',
      //datesHeading: 'Running Dates',
      datesHeading: 'Dates de diffusion',
      //dates: '<strong>{{startDate}}</strong> to <strong>{{endDate}}</strong>',
      dates: 'De <strong>{{startDate}}</strong> à <strong>{{endDate}}</strong>',
      //datesExplanation: 'Your campaign has run for <strong>{{days}} days</strong>, which is <strong>{{percentage}}</strong> of its maximum scheduled life.',
      datesExplanation:
        'La campagne a été diffusée pendant <strong> {{jours}} jours </strong>, ce qui correspond à <strong> {{percentage}} </strong> de la durée maximale.',
      //datesLeftExplanation: 'Your campaign will stop running in <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      datesLeftExplanation:
        "La campagne s'arrêtera dans <strong>{{days}} jours</strong> si le budget n'est pas atteint avant cette date.",
      //datesSubmittedExplanation: 'Your campaign will run for <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      datesSubmittedExplanation:
        "La campagne <strong>{{days}} sera diffusée </strong> si le budget n'est pas atteint avant cette date.",
      //impressions: 'Your campaign has delivered <strong>{{imps}} impressions</strong>, which is <strong>{{percentage}} of your budgeted impressions</strong>.',
      impressions:
        'La campagne a généré des <strong>{{imps}} affichages</strong>, ce qui correspond à <strong>{{percentage}} des affichages réservés </strong>.',
      //impressionsRemaining: 'You have about <strong>{{imps}} impressions</strong> left to deliver.',
      impressionsRemaining: 'Il reste environ <strong>{{imps}} affichages </strong> à effectuer.',
      clicks:
        'La campagne a généré des <strong>{{clicks}} clics</strong>, ce qui correspond à <strong>{{percentage}} des clics réservés </strong>.',
      clicksRemaining: 'Il reste environ <strong>{{clicks}} clics </strong> à effectuer.',
      //statusPrefix: 'Your campaign is ',
      statusPrefix: 'Situation de votre campagne ',
      //successfulPaymentParagraph1: 'Payment has been reserved and your campaign has been submitted for review.',
      successfulPaymentParagraph1: 'Le paiement a été réservé et la campagne a été envoyée pour examen.',
      //successfulPaymentParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      successfulPaymentParagraph2: 'Dès que la campagne sera approuvée, elle commencera à être diffusée!',
      prepay: {
        //stoppedParagraph1: 'Your campaign has been stopped.',
        stoppedParagraph1: 'La campagne a été arrêtée.',
        //stoppedParagraph2: 'Any unused campaign budget will be refunded and a report generated for your campaign.',
        stoppedParagraph2:
          'Tout budget de campagne non utilisé sera remboursé et un rapport sera généré pour votre campagne.',
      },
      manual: {
        //stoppedParagraph1: 'Your campaign has been stopped.',
        stoppedParagraph1: 'Votre campagne a été arrêtée.',
        //stoppedParagraph2: 'Payment will be taken from your account and a report generated for your campaign.',
        stoppedParagraph2: 'La campagne est facturée en fonction des affichages déjà effectués.',
      },
      //submittedParagraph1: 'Your campaign has been submitted for review.',
      submittedParagraph1: 'La campagne a été envoyée pour examen.',
      //submittedParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      submittedParagraph2: 'Dès que la campagne sera approuvée, elle commencera à être diffusée!',
      //unexpectedPaymentParagraph1: 'Your payment reservation did not proceed as planned.',
      unexpectedPaymentParagraph1: "Le paiement n'a pas été réservé comme prévu.",
      //unexpectedPaymentParagraph2: 'At this stage, it\'s probably best to copy this campaign and start the process again of creating a payment reservation.'
      unexpectedPaymentParagraph2:
        'Nous vous recommandons de copier la campagne et de recommencer le processus de paiement.',
    },
    save: {
      //button: 'Save Your Progress',
      button: 'Sauvegardez',
      //messageTitle: 'Campaign Saved',
      messageTitle: 'Campagne sauvegardée',
      //messageText: 'Your campaign was saved successfully.'
      messageText: 'Votre campagne a bien été sauvegardée.',
    },
    smoothing: {
      //title: 'Ad Delivery Style',
      title: 'Rythme de diffusion de la campagne',
      //true: 'Smooth',
      true: 'Régulier',
      //trueExplanation: 'Your advertisement will deliver smoothly over the life of your campaign.',
      trueExplanation: 'La campagne sera répartie uniformément sur la durée.',
      //trueField: '<strong>Smooth</strong> - deliver your budgeted ad impressions smoothly over the life of your campaign',
      trueField:
        '<strong>Diffusion uniforme</strong>: Diffusez les affichages réservés uniformément sur la période de la campagne.',
      trueFieldCpc:
        '<strong>Diffusion uniforme</strong>: Diffusez les clics réservés uniformément sur la période de la campagne.',
      //false: 'Fast',
      false: 'Rapide',
      //falseExplanation: 'Your advertisement will be delivered as quickly as possible.',
      falseExplanation: 'La campagne sera diffusée aussi rapidement que possible',
      //falseField: '<strong>Fast</strong> - deliver your budgeted ad impressions as quickly as possible'
      falseField: '<strong>Diffusion Rapide</strong> - Diffusez les affichages réservés dès que possible.',
      falseFieldCpc: '<strong>Diffusion Rapide</strong> - Diffusez les clics réservés dès que possible.',
    },
    status: {
      //STOPPED: 'Stopped',
      STOPPED: 'Stoppée',
      //PROPOSED: 'Drafted',
      PROPOSED: 'En cours de création',
      //SUBMITTED: 'In Review',
      SUBMITTED: 'En examen',
      //NOT_READY: 'Needs Further Review',
      NOT_READY: 'Nécessite un nouvel examen',
      //READY: 'Ready to Deliver',
      READY: 'Prêt à diffusion',
      //REJECTED: 'Rejected',
      REJECTED: 'Rejetée',
      //SUBMISSION_READY: 'Ready for Submission',
      SUBMISSION_READY: 'Prête à être soumise à examen',
      //RUNNING: 'Delivering',
      RUNNING: 'Active',
      //ENDED: 'Ended'
      ENDED: 'Terminée',
    },
    //statsHeading: 'Delivery Statistics',
    statsHeading: 'Statistiques de diffusion',
    approve: {
      button: 'Lecture',
      message: 'En Lecture'
    },
    pause: {
      button: 'Pause',
      message: 'En Pause'
    },
    controls: {title: 'Commandes de Campagne'},
    stop: {
      //button: 'Stop Campaign Delivery',
      button: 'Arrêter la diffusion de la campagne',
      //cancelButton: 'Cancel Campaign Stop',
      cancelButton: "Annuler l'arrêt de la campagne",
      //confirmButton: 'Confirm Campaign Stop',
      confirmButton: "Confirmer l'arrêt de la campagne",
      //failParagraph1: 'We encountered a problem and were not able to stop your campaign.',
      failParagraph1: "La campagne n'a pas pu être arrêtée en raison d'un problème",
      //failParagraph2: 'Please try stopping your campaign again, and if the problem persists, let us know.',
      failParagraph2: 'Veuillez réessayer pour arrêter la campagne. Veuillez nous contacter si le problème persiste',
      prepay: {
        //info1: 'Stopping a campaign cannot be reversed, although any campaign can be copied and the new campaign delivered.',
        info1: "L'arrêt de la campagne ne peut pas être annulé, mais la campagne peut être copiée puis réactivée",
        //info2: 'Upon stopping your campaign, any unused budget will be refunded. Note: a minimum fee of {{money}} does apply.',
        info2:
          "À l'arrêt de votre campagne, tout budget inutilisé sera remboursé. Remarque: des frais minimum de {{money}} s'appliquent.",
        info2NoMinFee: 'La campagne est facturée en fonction des affichages déjà effectués.',
      },
      manual: {
        //info1: 'Stopping a campaign cannot be reversed, although any campaign can be copied and the new campaign delivered.',
        info1: "L'arrêt de la campagne ne peut pas être annulé, mais la campagne peut être copiée puis réactivée",
        //info2: 'Upon stopping your campaign, either the minimum fee of {{money}} or your current advertising spend will be taken from your account, whichever figure is higher.',
        info2:
          'Jusquà la fin de la campagne, le montant minimum de {{money}} ou le budget déjà utilisé sera prélevé, en fonction du montant le plus élevé.',
        info2NoMinFee: 'La campagne est facturée en fonction des affichages déjà effectués.',
      },
      //intro: 'If at any time you wish to stop your campaign, you may do so below.'
      intro: "La campagne peut être arrêtée à tout moment à l'aide du bouton ci-dessous",
    },
    validationProblemsPresent: `
    <p>Il y a des problèmes de validation avec la campagne.</p>
    <p>Une fois les problèmes de validation résolus, nous pouvons procéder à la réservation du paiement.</p>
    <p>La campagne est ensuite envoyée pour examen et activée une fois qu'elle a été approuvée</p>
  `,
  },
  emailPreferences: {
    //CREATIVE_REJECTED: '<strong>Adjustments required:</strong> a reviewer requests a change to my campaign',
    CREATIVE_REJECTED: '<strong>Ajustements requis</strong>: le réviseur souhaite une modification de la campagne',
    //REPORT_COMPLETE: '<strong>Campaign report is ready:</strong> my campaign has finished and its performance report has been generated',
    REPORT_COMPLETE:
      '<strong>Le rapport de campagne est prêt:</strong> la campagne a expiré et le rapport sur les performances a été généré',
    //LINE_ITEM_RUNNING: '<strong>Campaign is delivering:</strong> my campaign has passed review and is now delivering impressions'
    LINE_ITEM_RUNNING:
      '<strong>La campagne est diffusée:</strong> ma campagne a passé l\'examen et est maintenant en cours de diffusion.',
  },
  media: {
    gif: 'GIF',
    png: 'PNG',
    svg: 'SVG',
    jpg: 'JPG',
  },
  chart: {
    countType: {
      singular: 'Tendance',
      cumulative: 'Total cumulé',
    },
    yAxisOption: {
      shared: 'Répartition',
      multiple: 'Cumul',
    },
    dateOption: {
      //last1Hour: 'Last hour',
      last1Hour: 'La dernière heure',
      //last12Hours: 'Last 12 hours',
      last12Hours: 'Les 12 dernières heures',
      //last7Days: 'Last 7 days',
      last7Days: 'les 7 derniers jours',
      //last30Days: 'Last 30 days',
      last30Days: 'Les 30 derniers jours',
      //today: 'Today',
      today: "Aujourd'hui",
      //yesterday: 'Yesterday',
      yesterday: 'Hier',
      //campaignDays: 'Running dates: {{startDate}}-{{endDate}}'
      campaignDays: 'Dates de diffusion: {{startDate}}-{{endDate}}',
    },
  },
  layout: {
    fieldSummary: {
      image: 'image',
      imagePlural: 'images',
      url: 'URL',
      urlPlural: 'URLs',
      code: 'champ code',
      codePlural: 'champs codes',
      text: 'champ texte',
      textPlural: 'champs texte',
      option: 'option',
      optionPlural: 'options',
      video: 'vidéo',
      videoPlural: 'vidéos',
    },
  },
  label: {
    //copy: 'Copy',
    copy: 'Copier',
    //copyLineItem: 'Copy Campaign',
    copyLineItem: 'Copier la campagne',
    //paginationAllShown: 'Showing all <strong>{{total}}</strong> results',
    paginationAllShown: 'Montrer tous les résultats de <strong>{{total}}</strong>',
    //paginationWithPages: 'Showing <strong>{{start}}-{{end}}</strong> of <strong>{{total}}</strong> results',
    paginationWithPages: 'Montrer les résultats <strong>{{start}}-{{end}}</strong> de <strong>{{total}}</strong>',
    //paginationLoading: 'Showing <span class="fa fa-spin fa-cog"></span> results'
    paginationLoading: 'Montrer les résultats <span class="fa fa-spin fa-cog"></span>',
  },
  report: {
    //downloadXlsComma: 'Download as XLS (decimal comma)',
    downloadXlsComma: 'Télécharger en XLS (avec virgule)',
    //downloadXlsPoint: 'Download as XLS (decimal point)',
    downloadXlsPoint: 'Télécharger en XLS (avec point)',
    fieldCountPlural: '{{count}} Champs',
    fieldCount: '{{count}} Champ',
    //report: 'Report',
    report: 'Rapport',
    //reportPlural: 'Reports',
    reportPlural: 'Rapports',
    noReport: `
    <h3>Aucun rapport trouvé</h3>
    <p>Dès la fin de la campagne, un rapport est généré.</p>
    <p>Voici la liste de tous les rapports générés pour les campagnes déjà diffusées.</p>
  `,
    status: {
      //PENDING: 'Pending',
      PENDING: 'En attente',
      //PROCESSING: 'Processing',
      PROCESSING: 'En cours de création',
      //COMPLETED: 'Completed',
      COMPLETED: 'Terminé',
      //REJECTED: 'Rejected',
      REJECTED: 'Rejeté',
      //FAILED: 'Failed',
      FAILED: 'A échoué',
      //CANCELLED: 'Cancelled',
      CANCELLED: 'A été annulé',
      //QUEUED: 'Queued',
      QUEUED: "En file d'attente",
      //TIMED_OUT: 'Timed Out'
      TIMED_OUT: 'Limite de temps dépassée',
    },
    field: {
      rendered: 'Affichages',
      visibles: 'Affichages visibles',
      visibility: 'Visibilité',
      viewables: 'Affichages visibles',
      viewability: 'Visibilité',
      uniqueUsers: 'Visiteurs uniques',
      clicks: 'Clics',
      ctr: 'Taux de Clic',
      cost: 'Coût',
    },
  },
  country: {
    FR: 'France',
  },
  errors: {
    standard: {
      unregistered: {
        title: 'Pas de compte enregistré',
        p1: "Vous n'êtes pas inscrit sur ce portail publicitaire.",
        p2: '',
        p2LinkText: 'Inscrivez-vous sur ce portail publicitaire',
        p2Suffix: 'avant de continuer',
        p3: 'Ou;',
        p3LinkText: 'Connectez-vous avec un compte différent',
        p3Suffix: '.',
      },
      //dataNotFoundTitle: 'Missing Data',
      dataNotFoundTitle: "Manque d'informations",
      //dataNotFoundParagraph1: 'We could not find any data at the following location: <strong>{{link}}</strong>.',
      dataNotFoundParagraph1: "Aucune donnée n'a pu être trouvée à ce stade: <strong>{{link}}</strong>.",
      //dataNotFoundParagraph2: 'This could have happened because the underlying data is no longer available to you.',
      dataNotFoundParagraph2:
        "Cela peut se produire si les données associées ne sont plus disponibles pour l'utilisateur.",
      //dataNotFoundParagraph3: 'Access any of the links in the menu to get back on track.',
      dataNotFoundParagraph3: "Veuillez utiliser l'un des liens du menu.",
      //pageNotFoundTitle: 'Page Not Found',
      pageNotFoundTitle: "La page n'a pas été trouvée",
      //pageNotFoundParagraph1: 'We could not find the page you requested.',
      pageNotFoundParagraph1: 'La page sélectionnée est introuvable.',
      //pageNotFoundParagraph2: 'Access any of the links in the menu to get back on track.',
      pageNotFoundParagraph2: "Veuillez utiliser l'un des liens du menu.",
      //objectNotFoundTitle: 'Object Not Found',
      objectNotFoundTitle: "L'objet n'a pas été trouvé",
      //objectNotFoundParagraph1: 'We could not find any data for the following id: <strong>{{id}}</strong>.',
      objectNotFoundParagraph1: "Aucune donnée n'a pu être trouvée pour l'ID suivant: <strong>{{id}}</strong>.",
      //objectNotFoundParagraph2: 'This could have happened because the object is no longer available to you.',
      objectNotFoundParagraph2: "Cela peut se produire si l'objet associé n'est plus disponible pour l'utilisateur.",
      //objectNotFoundParagraph3: 'Access any of the links in the menu to get back on track.'
      objectNotFoundParagraph3: "Veuillez utiliser l'un des liens du menu.",
    },
    api: {
      //stats: 'We were unable to retrieve statistics from the API. If the problem persists, please contact support.',
      stats: "Nous n'avons pas pu obtenir de données via l'API. Veuillez contacter le support si le problème persiste.",
      //title: 'Sorry, there appears to be a problem',
      title: 'Il semble y avoir un problème, nous nous excusons',
      noConnection: {
        //title: "Connection Loss",
        title: 'connexion perdue',
        //noConnection: "<p>We've lost connection to our back-end server. If the problem persists, please contact support.</p>"
        noConnection:
          '<p>Nous avons perdu la connexion au serveur. Veuillez contacter le support si le problème persiste.</p>',
      },
      //generic: "<p>We're experiencing problems interacting with our back-end system. If the problem persists, please contact support.</p>",
      generic:
        '<p>Nous avons perdu la connexion au serveur. Veuillez contacter le support si le problème persiste.</p>',
      unauthorised: {
        //title: "Unauthorised Operation",
        title: 'Opération non autorisée',
        //unauthorised: "<p>You do not have the permissions to perform the operation you requested.</p>"
        unauthorised: "<p>Vous ne disposez pas de l'autorisation nécessaire pour l'opération sélectionnée.</p>",
      },
    },
  },
  field: {
    advertiserName: "Nom de l'annonceur",
    address1: 'Adresse',
    city: 'Ville',
    postCode: 'Code Postal',
    state: 'Etat',
    country: 'Pays',
    invoiceEmail: 'E-Mail pour la facturation',
    phoneNumber: 'Téléphone',
    required: 'Obligatoire',
    optional: 'Optionnel',
    malformedEmail: 'Doit être un e-mail valide',
    cpm: 'Coût Pour 1000 affichages (CPM)',
    //selfServiceBudget: 'Budget',
    selfServiceBudget: 'Budget',
    email: 'Email',
    //emailPreferences: 'Email Preferences',
    emailPreferences: 'Préférences Email',
    //password: 'Password',
    password: 'Mot de passe',
    //confirmPassword: 'Confirm Password',
    confirmPassword: 'Confirmez le mot de passe',
    //locale: 'Locale',
    locale: 'Local',
    //smoothing: 'Ad Delivery Style',
    smoothing: 'Type de diffusion',
    //startDate: 'Start',
    startDate: 'Commencer',
    status: 'Statut',
    userState: 'Statut',
    //displayName: 'Display Name',
    displayName: "Nom d'usage",
    //displayNameHint: 'The display name is used to refer to you throughout the application',
    displayNameHint: "Le nom utilisé dans l'application pour faire référence à vous",
    //endDate: 'End',
    endDate: 'Fin',
    //executionStartTime: 'Run Date',
    executionStartTime: 'Date de diffusion',
    //name: 'Name'
    name: 'Nom',
  },
  settings: {
    //settingsPlural: 'Settings',
    settingsPlural: 'Paramètres',
    //about: 'About',
    about: 'A propos',
    //signOut: 'Sign Out'
    signOut: 'Déconnecter',
  },
  signIn: {
    //accountLocked: 'Your account has been locked due to too many failed login attempts. You have been sent an email with which you can reset your password, unlock your account and sign in.',
    accountLocked:
      "L'accès a été bloqué en raison d'un trop grand nombre d'entrées de mot de passe incorrectes. Pour réinitialiser le mot de passe, un e-mail a été envoyé à l'adresse e-mail utilisée.",
    //alreadyUser: 'Already a user?',
    alreadyUser: 'Déjà utilisateur?',
    //confirmPasswordMismatch: "The passwords you entered do not match. Ensure you've entered the same password both times.",
    confirmPasswordMismatch:
      "Les mots de passes que vous avez renseigné ne correspondent pas. Assurez d'entrer 2 fois le même mot de passe.",
    //differentAccount: 'Sign in with a different account',
    differentAccount: 'Connectez-vous avec un compte différent',
    //emailFirst: "Your account's email address must be entered before its password can be reset.",
    emailFirst: 'Votre adresse email doit être renseignée pour que que le mot de passe soit réactualisé.',
    //emailRequired: "We need your account's email address before we can sign you in.",
    emailRequired: 'Nous avons besoin de votre adresse email pour vous connecter.',
    //expiredLinkExplanation: 'It looks like you clicked on an invalid or expired password reset link. Please try resetting your password again.',
    expiredLinkExplanation:
      "Le lien pour réinitialiser le mot de passe n'est plus valide. Veuillez redémarrer la réinitialisation",
    //forgotPassword: 'Forgot your password?',
    forgotPassword: 'Mot de passe oublié?',
    returnHome: 'Aller à la page de connexion',
    //humanConfirmationFailure: "There's a problem obtaining registration confirmation for your account. Try again and let us know if the problem persists.",
    humanConfirmationFailure:
      "Il y a un problème avec l'enregistrement de l'utilisateur. Veuillez réessayer et contacter notre support si le problème persiste",
    //nameRequired: "We need a name with which to register you as a new user.",
    nameRequired: "Nous avons besoin d'un nom d'utilisateur pour vous inscrire.",
    //passwordResetTooMuchHeading: 'Too Many Password Resets',
    passwordResetTooMuchHeading: 'Trop de réinitialisations de mot de passe',
    //passwordResetTooMuchExplanation: 'You have tried to reset your password too often in a short space of time. You cannot reset your password for the next little while.',
    passwordResetTooMuchExplanation:
      'Trop de tentatives ont été faites pour réinitialiser le mot de passe dans un court laps de temps. ',
    //passwordResetEmailSentHeading: 'Password Reset Email Sent',
    passwordResetEmailSentHeading: 'Un e-mail avec le lien de réinitialisation a été envoyé.',
    //passwordResetEmailSentExplanation: 'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.',
    passwordResetEmailSentExplanation:
      'Veuillez rechercher dans la boîte aux lettres le courrier avec le lien de réinitialisation, veuillez également vérifier le dossier SPAM. ',
    //passwordHint: 'Your password must be at least 8 characters in length',
    passwordHint: 'Le mot de passe doit comporter au moins 8 caractères',
    //passwordLinkExpired: 'Your reset password link has expired. Enter your email address to generate another link.',
    passwordLinkExpired: 'Le lien pour réinitialiser le mot de passe a expiré. Veuillez recommencer le processus',
    //passwordRequired: "Supply a password to sign in.",
    passwordRequired: 'Veuillez saisir un mot de passe pour vous connecter.',
    //passwordSimple: 'The supplied password is too easily guessed and should be something less common.',
    passwordSimple: 'Le mot de passe est trop courant, veuillez choisir une autre combinaison de caractères.',
    regError: 'Problèmes de validation détectés. Voir les détails ci-dessus.',
    //registerAsUserHeading: 'Register as a User',
    registerAsUserHeading: 'Créer un utilisateur',
    //registerAsUserButton: 'Register',
    registerAsUserButton: 'Créer',
    //registerEmailRequired: 'We need an email address to register you as a new user.',
    registerEmailRequired: "Nous avons besoin d'une adresse e-mail pour l'inscription.",
    //registerNewUser: 'Register as a new user',
    registerNewUser: 'Créer un nouvel utilisateur',
    //registerSuccessHighlight: 'You have successfully registered as a new user!',
    registerSuccessHighlight: "L'utilisateur a été créé avec succès!",
    //registerSuccessParagraph1: 'You have been sent an email with more information.',
    registerSuccessParagraph1: 'Un e-mail contenant des informations complémentaires a été envoyé.',
    //registerSuccessParagraph2: 'Click on the link in your email to create your password for your account and sign in.',
    registerSuccessParagraph2:
      "Veuillez utiliser le lien dans l'e-mail pour déterminer le mot de passe, veuillez également vérifier le dossier SPAM.",
    registerTerms: `En sélectionnant <strong>Créer</strong>, vous acceptez d'avoir lu et accepté <a target="_ blank" href="https://adnuntius.com/resources/tc-self-service-advertising/">nos termes et conditions</a>.`,
    //resetPasswordHeading: 'Reset Your Password',
    resetPasswordHeading: 'Réinitialiser le mot de passe',
    //resetPasswordButton: 'Send Password Reset Email',
    resetPasswordButton: 'Envoyer un e-mail pour réinitialiser le mot de passe',
    //resetPasswordInfo: 'Enter your email address and we will send you a link to reset your password.',
    resetPasswordInfo: "Entrez l'e-mail pour obtenir un lien pour réinitialiser votre mot de passe.",
    //signInButton: 'Sign in',
    signInButton: 'Se connecter',
    //signInHeading: 'Sign in',
    signInHeading: 'Se connecter',
    //updatePasswordHeading: 'Update Your Password',
    updatePasswordHeading: 'Actualisez votre mot de passe',
    //updatePasswordButton: 'Update Password'
    updatePasswordButton: 'Actualiser',
  },
  adBlockerParagraph1:
    'Un bloqueur de publicités a été détecté. Cette application ne fonctionne pas avec les bloqueurs de publicités actifs.',
  adBlockerParagraph2: "Veuillez désactiver l'AdBlocker pour pouvoir utiliser l'application.",
  adBlockerTurnOff: 'Désactiver cet avertissement',
  validation: {
    //required: 'Required',
    required: 'Obligatoire',
    //pattern: "Text must be {{minLength}}-{{maxLength}} characters long.",
    pattern: 'Le texte doit comprendre {{minLength}} - {{maxLength}} caractères.',
    //maxSize: "The uploaded file is {{actual}}kb in size, which is greater than the {{maximum}}kb maximum",
    maxSize:
      'Le fichier importé a une taille de {{réel}} ko, ce qui est supérieur au maximum autorisé de {{maximum}} ko',
    //fileType: "The uploaded file must be one of the following types: {{allowedTypes}}",
    fileType: 'Les types de fichiers suivants sont autorisés: {{allowedTypes}}',
    //minPrice: "The minimum value is {{minValue}}",
    minPrice: 'Le montant minimum est {{minValue}}',
    maxPrice: 'La montant maximale est {{maxValue}}',
    number: 'Numéro invalide',
    //url: "The URL is not in a valid form.",
    url: "L'URL n'est pas valide.",
    //endDateStartDate: 'The end date must be after the start date.',
    endDateStartDate: 'La date de fin doit être postérieure à la date de début.',
    email: "Vous avez besoin d'une adresse e-mail valide",
    fileUploadFail: "Les fichiers suivants n'ont pas pu être téléchargés:",
    fileUploadTimeout:
      "Le téléchargement a échoué. Essayez de télécharger à nouveau ou, si possible, d'actualiser la page pour voir quelles ressources ont été téléchargées.",
    anonymousFileSizeLarge:
      "Le fichier doit avoir une taille inférieure à {{fileSize}}Ko lorsque vous n'êtes pas connecté.",
    anonymousFileSizeSuperLarge:
      "Le téléchargement du fichier a échoué pour une raison non spécifiée, plus que probablement parce que le fichier était trop volumineux pour que nous puissions l'analyser. Connectez-vous pour augmenter les limites de téléchargement de fichiers et obtenir une meilleure analyse.",
    parse: 'Le contenu ne correspond pas au format attendu',
    error: {
      //validation: '<strong>Invalid submission:</strong> Please check the details above and try again.',
      validation: '<strong> Entrée non valide: </strong> veuillez vérifier les détails et réessayer.',
      //'must.be.greater.than.or.equal': '{{value}} must be greater than {{minValue}}.',
      'must.be.greater.than.or.equal': '{{value}} doit être supérieur à {{minValue}}.',
      //'non.empty': 'Required'
      'non.empty': 'Obligatoire',
      'auth.failed': "La connexion a échoué, les données saisies n'ont pas pu être attribuées à un utilisateur.",
      'sign.in.blocked':
        "L'utilisateur n'est pas enregistré, veuillez vous connecter en utilisant l'URL suivante {{uiAddress}}.",
    },
    warning: {
      'creative.invalid.product.dimensions': "Veuillez choisir les dimensions d'annonce autorisées",
      //"lineItem.start.date.before.end.date": "The campaign's start date must be before its end date",
      'lineItem.start.date.before.end.date': 'La date de début de la campagne doit être antérieure à la date de fin',
      //"lineItem.end.date.before.today": "The campaign's end date must be after the current date and time",
      'lineItem.end.date.before.today': "La date de fin de la campagne doit être postérieure à aujourd'hui",
      //"lineItem.invalid.budget.enddate": "The campaign must end within 30 days"
      'lineItem.invalid.budget.enddate': 'La campagne doit se terminer dans les 100 jours',
      'payment.gateway.error': 'Il y a un problème avec la connexion.',
    },
  },
};
