type OPTIMISE_CREATIVES_FOR = 'CLICK';

type Favicon = {
  href: string;
  rel: string;
  type: string;
};

type CountryField = {
  mandatory: string;
  default: string;
  prio: Array<string>;
};

type Advertiser = {
  companyName: string;
  vatId?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: CountryField;
  invoiceEmail?: string;
  phoneNumber?: string;
};

export type NetworkData = {
  id: string;
  interface: string;
  urlSection: string;
  env: Array<string>;
  domain?: string;
  langPos?: string;
  lang?: string;
  langs?: Array<string>;
  bgs?: Array<string>;
  theme?: string;
  logo?: string;
  advertiser?: Advertiser;
  pageTitle?: string;
  inlineStylesheet?: string;
  favicon?: Favicon;
  fields?: Array<string>;
  locationOrder?: 'alpha';
  moreGranularLocations?: Array<string>;
  segmentOrder?: 'alpha';
  fixedLocale?: string;
  locationLocale?: string;
  hideSmoothing?: boolean;
  defaultSmoothed?: boolean;
  hideDateTimes?: boolean;
  stylesheets?: Array<string>;
  hasAnonymousCampaigns?: boolean;
  hasCreativeCopyrightStatus?: boolean;
  hasSignUpCondition?: boolean;
  hasSidebar?: boolean;
  hasReach?: boolean;
  previewSize?: string;
  showCoupons?: boolean;
  mandatoryCategories?: boolean;
  optimiseCreativesFor?: OPTIMISE_CREATIVES_FOR;
};

export const REGISTERED_URLS: Array<NetworkData> = [
  {
    id: 'adweb',
    interface: 'ADWEB',
    urlSection: 'adweb',
    domain: 'selfservice.adwebonedirect.com',
    env: ['production'],
    lang: 'el',
    langs: ['el', 'en'],
  },
  {
    id: 'gamma',
    interface: 'GAMMA',
    urlSection: 'gamma',
    domain: 'advertising.gammassp.com',
    env: ['production'],
    bgs: ['gamma'],
    theme: 'gamma',
    logo: 'https://regalia.adnuntius.com/label/gamma/gamma-logo.png',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/gamma/gamma-favicon.ico',
      rel: 'shortcut icon',
      type: 'image/x-icon',
    },
  },
  {
    id: 'da_trippers',
    interface: 'DA_TRIPPERS',
    urlSection: 'datrippers',
    env: ['production'],
    lang: 'el',
    langs: ['el', 'en', 'id', 'de'],
    fields: ['rendered', 'clicks', 'ctr'],
    hasAnonymousCampaigns: true,
    hasCreativeCopyrightStatus: true,
    hasSidebar: true,
    showCoupons: true,
  },
  {
    id: 'winning',
    interface: 'DEMO',
    urlSection: 'demo',
    env: ['production'],
  },
  {
    id: 'adnuntius',
    interface: 'ADNUNTIUS',
    urlSection: 'adn',
    env: ['production'],
  },
  {
    id: 'ais',
    interface: 'AIS',
    urlSection: 'ais',
    domain: 'booking-ads.than.th',
    bgs: ['ais'],
    pageTitle: 'AIS',
    lang: 'th',
    langs: ['th', 'en'],
    env: ['production'],
    logo: 'https://regalia.adnuntius.com/label/ais/THAN-200x40.png',
    showCoupons: true,
  },
  {
    id: 'jrw',
    interface: 'JRW',
    urlSection: 'jrw',
    env: ['production'],
  },
  {
    id: 'joph',
    interface: 'JOPH',
    urlSection: 'journal-online-ph',
    env: ['production'],
  },
  {
    id: 'dem',
    interface: 'DEMIROREN',
    urlSection: 'demiroren',
    env: ['production'],
  },
  {
    id: 'vpg3',
    interface: 'ABENDZEITUNG',
    urlSection: 'abendzeitung',
    env: ['production'],
    lang: 'de',
    langs: ['de', 'en'],
    bgs: ['az'],
    pageTitle: 'Abendzeitung',
    logo: 'https://regalia.adnuntius.com/label/az/image.png',
    theme: 'abendzeitung',
    domain: 'onlinewerbung.abendzeitung-muenchen.de',
    fields: ['rendered', 'clicks'],
    favicon: {
      href: 'https://regalia.adnuntius.com/label/az/az.png',
      rel: 'icon',
      type: 'image/png',
    },
    stylesheets: [
      'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
      'https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap',
    ],
    showCoupons: true,
    hasAnonymousCampaigns: true,
    hasCreativeCopyrightStatus: true,
  },
  {
    id: 'fmy0',
    interface: 'REFLEX',
    urlSection: 'reflex',
    env: ['production'],
    lang: 'de',
    langs: ['de', 'en'],
    bgs: ['reflex'],
    theme: 'reflex',
    domain: 'booking.mylocal-ad.de',
    pageTitle: 'Reflex-Media',
    logo: 'https://regalia.adnuntius.com/label/reflex/reflex-logo-2.png',
    hasAnonymousCampaigns: true,
    stylesheets: ['https://www.reflex-media.net/_mylocalad/mylocal.css'],
    favicon: {
      href: 'https://regalia.adnuntius.com/label/reflex/Reflex-Verlag-Favicon_16x16.jpg',
      rel: 'icon',
      type: 'image/jpg',
    },
  },
  {
    id: 'kl9q',
    interface: 'ADTECHFACTORY',
    urlSection: 'adtechfactory',
    env: ['production'],
    lang: 'de',
    langs: ['de', 'en'],
  },
  {
    id: 'pb',
    interface: 'TEST_PB',
    urlSection: 'testpb',
    env: ['production'],
  },
  {
    id: 'elevision_media',
    interface: 'ELEVISION',
    urlSection: 'elevision',
    env: ['production'],
  },
  {
    id: 'concept_cph',
    interface: 'CONCEPT',
    urlSection: 'concept',
    env: ['production'],
  },
  {
    id: '1v9y',
    interface: 'HAMBURGER',
    urlSection: 'hamburger',
    domain: 'www.werbelokal.de',
    env: ['production'],
    theme: 'hamburger',
    pageTitle: 'Mopo',
    lang: 'de',
    langs: ['de', 'en'],
    hasAnonymousCampaigns: true,
    hideDateTimes: true,
    hideSmoothing: true,
    hasSignUpCondition: true,
    showCoupons: true,
    bgs: ['mopo3'],
    logo: 'https://regalia.adnuntius.com/label/mopo/MOPO-logo.png',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/mopo/MOPO-favicon.png',
      rel: 'icon',
      type: 'image/png',
    },
    fields: ['rendered', 'visibles', 'clicks'],
  },
  {
    id: '8nc0',
    interface: 'KLY',
    urlSection: 'kly',
    env: ['production'],
    lang: 'id',
    langs: ['id', 'en'],
  },
  {
    id: 'bm',
    interface: 'BRAND_MAMMOTH',
    urlSection: 'bm',
    domain: 'trader.brandmammoth.com',
    env: ['production'],
    theme: 'bm',
    logo: 'https://regalia.adnuntius.com/label/bm/bm.jpg',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/bm/bm.jpg',
      rel: 'icon',
      type: 'image/jpg',
    },
  },
  {
    id: 'y5rx',
    interface: 'SUMMIT',
    urlSection: 'summit',
    env: ['production'],
    bgs: ['summit'],
    domain: 'advertise.summitmedia-digital.com',
    logo: 'https://regalia.adnuntius.com/label/summit/Top-Menu-Logo-Summit-35x40px.png',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/summit/Summit-Favicon--16x16px.png',
      rel: 'icon',
      type: 'image/png',
    },
  },
  {
    id: 'tencent_thailand',
    interface: 'TENCENT_THAILAND',
    urlSection: 'sanook',
    env: ['production'],
  },
  {
    id: 'russ',
    interface: 'RUSSMEDIA',
    urlSection: 'russmedia',
    domain: 'ads.russmedia.com',
    logo: 'https://regalia.adnuntius.com/label/russmedia/Russmedia-Logo-white.png',
    advertiser: {
      companyName: 'mandatory',
      vatId: 'mandatory',
      address1: 'mandatory',
      address2: 'optional',
      city: 'mandatory',
      state: 'optional',
      postCode: 'mandatory',
      country: {
        mandatory: 'mandatory',
        default: 'AT',
        prio: ['AT', 'DE', 'CH'],
      },
      invoiceEmail: 'mandatory',
      phoneNumber: 'mandatory',
    },
    favicon: {
      href: 'https://regalia.adnuntius.com/label/russmedia/201209_Russmedia_favicon_16x16.png',
      rel: 'icon',
      type: 'image/png',
    },
    bgs: ['russ'],
    theme: 'russ',
    env: ['production'],
    lang: 'de',
    langs: ['de', 'en'],
    fields: ['rendered', 'clicks', 'ctr'],
  },
  {
    id: 'ptimd',
    interface: 'PT_INFO_MEDIA',
    urlSection: 'pt',
    bgs: ['pt'],
    theme: 'pt',
    logo: 'https://regalia.adnuntius.com/label/pt/tempo-logo.png',
    env: ['production'],
    langPos: 'inside',
    lang: 'id',
    langs: ['id', 'en'],
  },
  {
    id: 'buyers_network',
    interface: '',
    urlSection: 'market',
    env: ['localhost', 'coreuidist', 'vagrant'],
    hasAnonymousCampaigns: true,
    optimiseCreativesFor: 'CLICK',
    lang: 'en',
    langs: ['de', 'el', 'en', 'es', 'fr', 'id', 'is', 'no', 'th'],
  },
  {
    id: 'network_1',
    interface: '',
    urlSection: 'net',
    env: ['localhost', 'coreuidist', 'vagrant'],
    hasAnonymousCampaigns: true,
    mandatoryCategories: true,
    hasCreativeCopyrightStatus: true,
    showCoupons: true,
    advertiser: {
      companyName: 'mandatory',
      vatId: 'mandatory',
      phoneNumber: 'mandatory',
    },
    optimiseCreativesFor: 'CLICK',
    lang: 'en',
    hasReach: true,
    langs: ['de', 'el', 'en', 'es', 'fr', 'id', 'is', 'no', 'th'],
  },
  {
    id: 'network_2',
    interface: '',
    urlSection: 'net2',
    env: ['localhost', 'coreuidist', 'vagrant'],
  },
  {
    id: 'dev_network',
    interface: '',
    urlSection: 'net',
    env: ['dev'],
    advertiser: {
      companyName: 'mandatory',
      vatId: 'optional',
    },
    hasAnonymousCampaigns: true,
  },
  {
    id: 'gota_media',
    interface: 'TEST1',
    urlSection: 'test1',
    env: ['production'],
  },
  {
    id: 'x60p',
    interface: 'NEUROCHILD',
    urlSection: 'neurochild',
    env: ['production'],
  },
  {
    id: 'ballade',
    interface: 'TEST4',
    urlSection: 'test4',
    env: ['production'],
  },
  {
    id: 'kf2z',
    interface: 'ADREV',
    urlSection: 'adrev',
    theme: 'adrev',
    domain: 'bookings.adrevmedia.net',
    env: ['production'],
    logo: 'https://regalia.adnuntius.com/label/adrev/adrev-logo-1.png',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/adrev/adrev-logo-1.png',
      rel: 'icon',
      type: 'image/png',
    },
  },
  {
    id: 'df',
    interface: 'DIGITALFUEL',
    urlSection: 'digitalfuel',
    env: ['production'],
  },
  {
    id: 'v8t7',
    interface: 'CONFIDENCIAL',
    urlSection: 'elconfidencial',
    env: ['production'],
    lang: 'es',
    langs: ['es', 'en'],
    bgs: ['confi'],
    theme: 'confidencial',
    logo: 'https://regalia.adnuntius.com/label/confi/logo-menu.jpg',
    domain: 'selfservice.elconfidencialdigital.com',
    locationOrder: 'alpha',
    segmentOrder: 'alpha',
    locationLocale: 'es',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/confi/ecd_favicon.ico',
      rel: 'shortcut icon',
      type: 'image/x-icon',
    },
  },
  {
    id: 'qlzz',
    interface: 'ATTENKOFERSCHE',
    urlSection: 'attenkofersche',
    env: ['production'],
    lang: 'de',
    hasAnonymousCampaigns: true,
    theme: 'atten',
    bgs: ['atten'],
    domain: 'buchungstool.idowa.de',
    logo: 'https://regalia.adnuntius.com/label/atten/idowa-logo-smaller.png',
    stylesheets: [
      'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
      'https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap',
    ],
    favicon: {
      href: 'https://regalia.adnuntius.com/label/atten/favicon.ico',
      rel: 'shortcut icon',
      type: 'image/x-icon',
    },
  },
  {
    id: 'td',
    interface: 'TEST_DIRK',
    urlSection: 'td',
    env: ['production'],
  },
  {
    id: 'nxts',
    interface: 'EPPSTEINER',
    urlSection: 'eppsteiner',
    env: ['production'],
    domain: 'werben.eppsteiner-zeitung.de',
    lang: 'de',
    langs: ['de', 'en'],
    theme: 'epp',
    bgs: ['epps'],
    hasAnonymousCampaigns: true,
    inlineStylesheet: `
      @font-face {
        font-family: 'Liberation-Sans-Narrow';
        src: url('https://regalia.adnuntius.com/label/epp/Liberation-Sans-Narrow.ttf.eot'); /* IE9 Compat Modes */
        src: url('https://regalia.adnuntius.com/label/epp/Liberation-Sans-Narrow.ttf.woff') format('woff'), /* Pretty Modern Browsers */
             url('https://regalia.adnuntius.com/label/epp/Liberation-Sans-Narrow.ttf.woffwebfont.svg#Liberation-Sans-Narrow') format('svg'); /* Legacy iOS */
      }
    `,
    favicon: {
      href: 'https://regalia.adnuntius.com/label/epp/fav.ico',
      rel: 'shortcut icon',
      type: 'image/x-icon',
    },
  },
  {
    id: 'syn',
    interface: 'SYN',
    urlSection: 'syn',
    env: ['production'],
    lang: 'is',
    langs: ['is', 'en'],
  },
  {
    id: 'vjvv',
    interface: 'PRISA',
    urlSection: 'prisa',
    env: ['production'],
    lang: 'es',
    langs: ['es', 'en'],
    domain: 'selfservice.prisabs.com',
  },
  {
    id: 'burda',
    interface: 'BURDA',
    urlSection: 'burda',
    env: ['production'],
    domain: 'directbooking.nebenan.de',
    lang: 'de',
    langs: ['de', 'en'],
    theme: 'burda',
    bgs: ['burda'],
    previewSize: 'STRETCHED',
    logo: 'https://regalia.adnuntius.com/label/burda/nebenan-logo-top-right.png',
    fields: ['rendered', 'clicks', 'ctr', 'uniqueUsers'],
    stylesheets: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap'],
    advertiser: {
      companyName: 'mandatory',
    },
    favicon: {
      href: 'https://regalia.adnuntius.com/label/burda/Haus-Icon40x40px.jpg',
      rel: 'shortcut icon',
      type: 'image/jpg',
    },
  },
  {
    id: 'gwtg',
    interface: 'GARDUOTO',
    urlSection: 'garduoto',
    env: ['production'],
    lang: 'id',
    langs: ['id', 'en'],
  },
  {
    id: 'f5gc',
    interface: 'FOYSTER',
    urlSection: 'foyster',
    env: ['production'],
    theme: 'foyster',
    bgs: ['foyster'],
    logo: 'https://regalia.adnuntius.com/label/foyster/foyster-banner-logo-smaller.png',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/foyster/fm.png',
      rel: 'icon',
      type: 'image/png',
    },
  },
  {
    id: '2dsr',
    interface: 'KHABAR',
    urlSection: 'khabar',
    env: ['production'],
  },
  {
    id: 'd966',
    interface: 'PHILSTAR',
    urlSection: 'philstar',
    env: ['production'],
    theme: 'philstar',
    bgs: ['philstar'],
    logo: 'https://regalia.adnuntius.com/label/philstar/logo.png',
    domain: 'advertising.philstar.com',
    advertiser: {
      companyName: 'mandatory',
      vatId: 'mandatory',
      address1: 'mandatory',
      address2: 'optional',
      city: 'mandatory',
      state: 'optional',
      postCode: 'mandatory',
      country: {
        mandatory: 'mandatory',
        default: 'PH',
        prio: ['PH', 'SG', 'AU'],
      },
      invoiceEmail: 'mandatory',
      phoneNumber: 'mandatory',
    },
    stylesheets: ['https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'],
    favicon: {
      href: 'https://regalia.adnuntius.com/label/philstar/favicon.jpg',
      rel: 'icon',
      type: 'image/jpg',
    },
  },
  {
    id: 'sdx1',
    interface: 'HEGNAR',
    urlSection: 'hegnar',
    env: ['production'],
  },
  {
    id: '67qx',
    interface: 'IMMEDIATE',
    urlSection: 'immediate',
    env: ['production'],
    domain: 'bookings.immediate.co.uk',
    theme: 'imml',
    bgs: ['imml'],
    logo: 'https://regalia.adnuntius.com/label/imml/Adnuntius-IM-Logo-99x40.png',
    hasAnonymousCampaigns: true,
    hasReach: true,
    locationOrder: 'alpha',
    moreGranularLocations: ['United Kingdom'],
    favicon: {
      href: 'https://regalia.adnuntius.com/label/imml/Adnuntius-IM-Favicon-16x16.jpg',
      rel: 'icon',
      type: 'image/jpg',
    },
    optimiseCreativesFor: 'CLICK',
  },
  {
    id: 'svt3',
    interface: 'IMMEDIATE_BRISTOL',
    urlSection: 'immediate-bristol',
    env: ['production'],
    domain: 'bookings.ourmedia.co.uk',
    theme: 'immb',
    hasReach: true,
    bgs: ['immb'],
    logo: 'https://regalia.adnuntius.com/label/immb/logo.png',
    hasAnonymousCampaigns: true,
    optimiseCreativesFor: 'CLICK',
  },
  {
    id: 'zlq1',
    interface: 'MAYYAN',
    urlSection: 'mayyan',
    env: ['production'],
  },
  {
    id: 'tp6m',
    interface: 'STROER',
    urlSection: 'stroer',
    env: ['production'],
    lang: 'de',
    langs: ['de', 'en'],
  },
  {
    id: 'zqjq',
    interface: 'SPECIALTIES',
    urlSection: 'specialties',
    env: ['production'],
    theme: 'specialties',
    bgs: ['specialties'],
    logo: 'https://regalia.adnuntius.com/label/specialties/baseoilnewslogo1000-2.png',
    hasAnonymousCampaigns: true,
    favicon: {
      href: 'https://regalia.adnuntius.com/label/specialties/baseoilnewsfavicon.png',
      rel: 'icon',
      type: 'image/png',
    },
  },
  {
    id: 'ipl',
    interface: 'IPL',
    urlSection: 'ipl',
    env: ['production'],
    domain: 'bookings.ipdigital.lu',
    theme: 'ipl',
    bgs: ['ipl'],
    logo: 'https://regalia.adnuntius.com/label/ipl/logo-ipl.png',
    lang: 'fr',
    langs: ['fr', 'en'],
    favicon: {
      href: 'https://regalia.adnuntius.com/label/ipl/favicon.png',
      rel: 'icon',
      type: 'image/png',
    },
  },
  {
    id: 'try_opt',
    interface: 'C_ALT',
    urlSection: 'calt',
    env: ['production'],
    theme: 'calt',
    bgs: ['calt'],
    logo: 'https://regalia.adnuntius.com/label/calt/calt-white.png',
    advertiser: {
      companyName: 'mandatory',
      vatId: 'mandatory',
      phoneNumber: 'mandatory',
    },
  },
  {
    id: '07l5',
    interface: 'BUSINESSAD',
    urlSection: 'businessad',
    domain: 'selfservice.businessad.de',
    env: ['production'],
    theme: 'busad',
    bgs: ['busad1', 'busad2', 'busad3', 'busad4'],
    lang: 'de',
    langs: ['de', 'en'],
    hasAnonymousCampaigns: true,
    logo: 'https://regalia.adnuntius.com/label/businessad/BA_Logo_white-outline.png',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/businessad/ba-favicon.png',
      rel: 'icon',
      type: 'image/png',
    },
  },
  {
    id: '77zq',
    interface: 'HALLINGDOLEN',
    urlSection: 'hallingdolen',
    env: ['production'],
    lang: 'no',
    langs: ['no', 'en'],
    theme: 'hallingdolen',
    bgs: ['hallingdolen'],
    logo: 'https://regalia.adnuntius.com/label/hallingdolen/logo-white.svg',
  },
  {
    id: 't2s0',
    interface: 'INFRANKEN',
    urlSection: 'infranken',
    env: ['production'],
    domain: 'lokalwerben.infranken.de',
    pageTitle: 'lokalwerben.inFranken.de',
    lang: 'de',
    langs: ['de'],
    hasAnonymousCampaigns: true,
    fixedLocale: 'de',
    hideSmoothing: true,
    hideDateTimes: true,
    fields: ['rendered', 'clicks'],
    bgs: ['infranken'],
    theme: 'infranken',
    defaultSmoothed: false,
    logo: 'https://regalia.adnuntius.com/label/infranken/logo4.png',
    stylesheets: ['https://www.infranken.de/storage/css/1670334238_20221212124514/281_nunitosans.css_2_ssl.css'],
    favicon: {
      href: 'https://regalia.adnuntius.com/label/infranken/favicon-transparent.png',
      rel: 'icon',
      type: 'image/png',
    },
    hasCreativeCopyrightStatus: true,
  },
  {
    id: 'tkr5',
    interface: 'FJELL-LJOM',
    urlSection: 'fjell-ljom',
    lang: 'no',
    langs: ['no', 'en'],
    bgs: ['fjell'],
    theme: 'fjell',
    logo: 'https://regalia.adnuntius.com/label/fjell-ljom/logo-transparent.png',
    hasAnonymousCampaigns: true,
    env: ['production'],
    favicon: {
      href: 'https://regalia.adnuntius.com/label/fjell-ljom/fav.ico',
      rel: 'icon',
      type: 'image/x-icon',
    },
  },
  {
    id: '2f7b',
    interface: 'MEINESTADT',
    urlSection: 'meinestadt',
    env: ['production'],
    domain: 'lokalwerben.meinestadt.de',
    lang: 'de',
    langs: ['de'],
    hasAnonymousCampaigns: true,
    theme: 'meinestadt',
    bgs: ['meinestadt'],
    fields: ['rendered', 'clicks', 'ctr', 'cost'],
    mandatoryCategories: true,
    showCoupons: true,
    logo: 'https://regalia.adnuntius.com/label/meinestadt/ms-logo.png',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/meinestadt/favicon-16x16.png',
      rel: 'icon',
      type: 'image/png',
    },
    hasCreativeCopyrightStatus: true,
  },
  {
    id: 'nkjm',
    interface: 'MEDIASQUARE',
    urlSection: 'mediasquare',
    hasAnonymousCampaigns: true,
    optimiseCreativesFor: 'CLICK',
    env: ['production'],
    lang: 'fr',
    langs: ['fr', 'en'],
    fields: ['rendered', 'viewables', 'viewability', 'clicks', 'ctr', 'cost'],
    theme: 'mediasquare',
    bgs: ['mediasquare'],
    pageTitle: 'Media Square',
    logo: 'https://assets.adnuntius.com/ZxrPk71X0QRHz9VjoaGyubjZyPRrXl8CMd0SZJMwy7c.png',
    favicon: {
      href: 'https://assets.adnuntius.com/Tfg7g0v7LP9T2X-J_gdn12k1PjFsmpTUFMzAMO6ARmw.png',
      rel: 'icon',
      type: 'image/jpg',
    },
  },
  {
    id: 'dpvc',
    interface: 'MARKETPLACE_TEST',
    urlSection: 'marketplacetest',
    env: ['production'],
    hasAnonymousCampaigns: true,
    optimiseCreativesFor: 'CLICK',
    advertiser: {
      companyName: "mandatory",
      vatId: "mandatory",
      phoneNumber: "mandatory"
    },
    lang: 'fr',
    langs: ['fr', 'en'],
    fields: ['rendered', 'viewables', 'viewability', 'clicks', 'ctr', 'cost'],
    theme: 'mediasquare',
    bgs: ['mediasquare'],
    pageTitle: 'Media Square',
    logo: 'https://assets.adnuntius.com/ZxrPk71X0QRHz9VjoaGyubjZyPRrXl8CMd0SZJMwy7c.png',
    favicon: {
      href: 'https://assets.adnuntius.com/Tfg7g0v7LP9T2X-J_gdn12k1PjFsmpTUFMzAMO6ARmw.png',
      rel: 'icon',
      type: 'image/jpg',
    },
  },
  {
    id: 'l16s',
    interface: 'ONE_ID',
    urlSection: 'oneid',
    env: ['production'],
    hasAnonymousCampaigns: true,
    optimiseCreativesFor: 'CLICK',
    advertiser: {
      companyName: "mandatory",
      vatId: "mandatory",
      phoneNumber: "mandatory"
    },
    lang: 'fr',
    langs: ['fr', 'de', 'en'],
    fields: ['rendered', 'clicks', 'ctr', 'cost'],
    theme: 'oneid',
    bgs: ['oneid'],
    pageTitle: 'One ID',
    logo: 'https://assets.adnuntius.com/cfadbe/rgx808j1wklvt51qj3kjcxgw1sp6dwzjfx7bml78jsvkg75dg8.png',
    favicon: {
      href: 'https://assets.adnuntius.com/cfadbe/675716rdz9j5mrnm00njw7hzxgmxfb0ftjddvqf2tznzdyfjmv.svg',
      rel: 'icon',
      type: 'image/svg',
    },
  },
];
