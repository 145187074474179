import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {Coupon} from '@app/@core/@models/design/coupon.model';
import {Payment, PaymentCalculation} from '@app/@core/@models/network/payment.model';
import {EMPTY, finalize, Observable, of, takeWhile, tap} from 'rxjs';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import {PaymentResource} from '@app/@core/@rest/admin/payment-resource.service';
import {catchError, switchMap} from 'rxjs/operators';
import {Money} from '@app/@core/@models/network/money.model';
import {Uuid} from '@shared/utils/uuid';
import {Network} from '@app/@core/@models/network/network.model';

@Component({
  selector: 'app-paypal-payment[campaignDraft][coupon][paymentCalculation][isSubmitting]',
  templateUrl: './paypal-payment.component.html',
  styleUrls: ['./paypal-payment.component.scss']
})
export class PaypalPaymentComponent implements OnInit {
  @Input() campaignDraft!: CampaignDraft;
  @Input() coupon?: Coupon;
  @Input() paymentCalculation!: PaymentCalculation;
  @Output() isSubmitting = new EventEmitter<boolean>();
  submit$?: Observable<any>;

  constructor(public i18nFormatService: I18nFormatService,
              private paymentResource: PaymentResource) {
  }

  ngOnInit(): void {
  }

  public onSubmit() {
    this.isSubmitting.next(true);
    this.submit$ = of(undefined)
      .pipe(
        // create payment
        switchMap(() =>
          this.paymentResource.post(this.createPaymentMsg())
        ),
        // check payment response doesn't have validation warnings
        takeWhile(payment => {
          const success = payment.validationWarnings.length === 0;
          if (!success) {
            this.campaignDraft.sendUpdate({
              errorKey: 'campaign.payment.failure'
            });
          }
          return success;
        }),
        // redirect to authorisationUrl
        tap((payment) => {
          window.location.replace(payment.authorisationUrl);
        }),
        // error handling
        catchError(() => {
          this.campaignDraft.sendUpdate({
            errorKey: 'campaign.payment.failure'
          })
          return EMPTY;
        }),
        finalize(() => {
          this.isSubmitting.next(false);
          this.submit$ = undefined
        })
      );
  }

  getChargeAmount(paymentCalculation: PaymentCalculation): Money {
    if (paymentCalculation.amountWithDiscountApplied) {
      return paymentCalculation.amountWithDiscountApplied;
    } else {
      return this.campaignDraft.lineItemReview.lineItem.selfServiceBudget!;
    }
  }

  getBudgetedImpressions(): number {
    const product = this.campaignDraft.getProduct()!;
    return (1000 * this.campaignDraft.lineItemReview.lineItem.selfServiceBudget!.amount) / (product.minimumCpm?.amount || product.minimumCpc?.amount);
  }

  private createPaymentMsg(): Payment {
    const paymentId = Uuid.generate();
    const baseUrl = window.location.href.split('?')[0]
    const payment = {
      cancelUrl: baseUrl + `?payment=cancelled&paymentId=${paymentId}`,
      id: paymentId,
      network: {id: this.campaignDraft.network.id,} as Network,
      lineItem: this.campaignDraft.lineItemReview.lineItem.id,
      returnUrl: baseUrl + `?payment=approved&paymentId=${paymentId}`,
    } as Payment;
    if (this.coupon) {
      payment.coupon = this.coupon.id;
    }
    return payment;
  }
}
