<h2>{{ 'campaign.detailsTitle' | translate }}</h2>
<app-timezone-warning [network]="campaignDraft.network"></app-timezone-warning>
<ngb-alert class='d-grid gap-2' *ngIf='generalValidationWarnings.length > 0' [dismissible]='false'>
  <div *ngFor='let validationWarning of generalValidationWarnings'>
    {{ validationWarning.text }}
  </div>
</ngb-alert>
<div [formGroup]='campaignDraft.campaignForm' class='row mb-1'>
  <div class='col-6'>
    <div class='mb-3'>
      <label class='form-label' for='nameInput'
      >{{ 'field.name' | translate }}</label
      >
      <input
        type='text'
        class='form-control'
        id='nameInput'
        formControlName='name'
        [ngClass]="{ 'is-invalid': campaignDraft.campaignForm.controls.name.invalid }"
        data-lpignore='true'
      />
      <div *ngFor='let err of campaignDraft.campaignForm.controls.name.errors | keyvalue' class='invalid-feedback'>
        {{ 'validation.' + err.key | translate }}
      </div>
    </div>
    <div class='mb-3'>
      <app-date-time-input [dateControl]="campaignDraft.campaignForm.controls.startDate"
                           [timeControl]="campaignDraft.campaignForm.controls.startTime"
                           labelKey='field.startDate' [validationWarnings]="startDateValidationWarnings"
      ></app-date-time-input>
    </div>
    <div class='mb-3'>
      <app-date-time-input [dateControl]="campaignDraft.campaignForm.controls.endDate"
                           [timeControl]="campaignDraft.campaignForm.controls.endTime"
                           labelKey='field.endDate' [validationWarnings]="endDateValidationWarnings"
      ></app-date-time-input>
    </div>
    <app-budget [lineItem]="campaignDraft.lineItemReview.lineItem" [campaignForm]="campaignDraft.campaignForm"
                [anon]="campaignDraft.anon" [campaignDraft]="campaignDraft"></app-budget>
    <div *ngIf="!networkData.hideSmoothing" class='mb-3'>
      <app-ad-delivery-style [campaignDraft]='campaignDraft'></app-ad-delivery-style>
    </div>
    <app-campaign-reach-estimate *ngIf="!campaignDraft.anon && networkData.hasReach"
                                 [lineItem]="campaignDraft.lineItemReview.lineItem"
                                 [campaignForm]="campaignDraft.campaignForm"
                                 class="mb-3"></app-campaign-reach-estimate>
  </div>
  <ng-container *ngIf="campaignDraft.network.paymentProvider !== 'MANUAL_INVOICING'">
    <div class='col-6' *ngIf="campaignDraft.network.minimumCampaignFee && campaignDraft.network.minimumCampaignFee.amount > 0"
         [innerHTML]="'campaign.payment.prepay.detailsInfo' | translate : {minimumFee: i18nFormatService.currency(campaignDraft.network.minimumCampaignFee)}"
    ></div>
    <div class='col-6' *ngIf="!campaignDraft.network.minimumCampaignFee || campaignDraft.network.minimumCampaignFee.amount === 0"
         [innerHTML]="'campaign.payment.prepay.detailsInfoNoMinFee' | translate"></div>
  </ng-container>
  <ng-container *ngIf="campaignDraft.network.paymentProvider === 'MANUAL_INVOICING'">
    <div class='col-6' *ngIf="campaignDraft.network.minimumCampaignFee && campaignDraft.network.minimumCampaignFee.amount > 0"
         [innerHTML]="'campaign.payment.manual.detailsInfo' | translate : {minimumFee: i18nFormatService.currency(campaignDraft.network.minimumCampaignFee)}"
    ></div>
    <div class='col-6' *ngIf="!campaignDraft.network.minimumCampaignFee || campaignDraft.network.minimumCampaignFee.amount === 0"
         [innerHTML]="'campaign.payment.manual.detailsInfoNoMinFee' | translate"></div>
  </ng-container>
</div>
