import {Injectable} from '@angular/core';
import {MediaTypeHelper} from '@app/@core/design/media-type-helper.service';
import {FormArray, FormControl, NonNullableFormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {NetworkData} from '@app/@core/@config/registered-urls-data';
import {Network} from '@app/@core/@models/network/network.model';
import {Product} from '@app/@core/@models/design/product.model';
import {Layout, LayoutReference} from '@app/@core/@models/advertising/layout/layout.model';
import {CreativeReview, LineItemReview} from '@app/@core/@models/advertising/line-item-review.model';
import {ProductSelectionData} from '@app/@core/@models/network/network-data.model';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {BidSpecification, LineItem} from '@app/@core/@models/advertising/lineitem.model';
import {DateTime} from 'luxon';
import {Uuid} from '@shared/utils/uuid';
import {ValidationWarningMessage} from '@app/@core/@models/advertising/validation-warning-message.model';
import {Creative, ImageParameter, LayoutParameters} from '@app/@core/@models/advertising/creative.model';
import {CampaignForm} from '@app/@core/advertising/campaign/campaign-form';
import {AdvertForm} from '@app/@core/advertising/campaign/advert/advert-form';
import {LayoutComponentForm} from '@app/@core/advertising/campaign/layout/layout-component-form';
import {Targeting} from '@app/@core/@models/targeting/targeting.model';
import {TargetingForm} from '@app/@core/advertising/campaign/advert/targeting-form';
import {DevicePropertyTarget} from '@app/@core/@models/targeting/device-property-target.model';
import _some from 'lodash-es/some';
import _indexOf from 'lodash-es/indexOf';
import {NamedLocation, NamedLocationTarget} from '@app/@core/@models/targeting/named-location-target.model';
import {CategoryTarget} from '@app/@core/@models/targeting/category-target.model';
import {SiteTarget} from '@app/@core/@models/targeting/site-target.model';
import {UserSegmentTarget} from '@app/@core/@models/targeting/user-segment-targets.model';
import {LayoutComponent} from '@app/@core/@models/advertising/layout/layout-component.model';
import {isAssetComponent} from '@app/@core/@models/advertising/layout/asset-component.model';
import {isTextComponent} from '@app/@core/@models/advertising/layout/text-component.model';
import {isUrlComponent} from '@app/@core/@models/advertising/layout/url-component.model';
import {urlValidator} from '@app/@core/advertising/campaign/layout/url-validator';
import {isChoiceComponent} from '@app/@core/@models/advertising/layout/choice-component.model';
import {ConfigService} from '@app/@core/@config/config.service';
import {DomainNameTarget} from '@app/@core/@models/targeting/domain-name-target.model';
import {ResultList} from '@app/@core/@models/common/result-list';
import {Coupon} from '@app/@core/@models/design/coupon.model';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignDraftFactory {

  constructor(private configService: ConfigService,
              private i18nFormatService: I18nFormatService) {
  }

  newCampaignDraft(mediaTypeHelper: MediaTypeHelper, fb: NonNullableFormBuilder, lineItemId: string | undefined, networkData: NetworkData, network: Network, anon: boolean, products: Product[], layouts: Layout[],
                   lineItemReview: LineItemReview | undefined, productSelectionData: ProductSelectionData | undefined,
                   saved: boolean, signUpCoupons: ResultList<Coupon> | undefined): CampaignDraft {
    if (!lineItemReview) {
      const lineItem = this.newCampaignLineItem(networkData, network, undefined, lineItemId);
      const creative = this.newCreative(undefined, lineItem.id, lineItem.name);
      lineItemReview = {
        lineItem: lineItem,
        product: undefined,
        creatives: [
          {
            assets: [],
            creative: creative,
            layout: undefined
          } as CreativeReview,
        ],
      } as LineItemReview;
    }
    const campaignForm = this.campaignFormFromLineItemReview(mediaTypeHelper, fb, network, lineItemReview, products, layouts)
    if (!saved) {
      campaignForm.markAsDirty();
    }
    return new CampaignDraft(this, fb, this.configService, mediaTypeHelper, network, anon, products, layouts, lineItemReview, campaignForm, productSelectionData, signUpCoupons);
  }

  newCampaignLineItem(networkData: NetworkData, network: Network, product: Product | undefined, lineItemId: string | undefined): LineItem {
    const startDateTime = DateTime.now().setZone(network.timeZone);
    const endDateTime = startDateTime.plus({weeks: 2}).endOf('day');
    const lineItem: LineItem = {
      bidSpecification: {} as BidSpecification,
      targeting: {},
      type: 'AUCTION',
      companionAds: 'NONE',
      smoothed: 'defaultSmoothed' in networkData ? networkData.defaultSmoothed : true,
      userState: 'PROPOSED',
      objectState: 'ACTIVE',
      id: lineItemId || Uuid.generate(),
      isNew: true,
      name: product ? product.name + ' Campaign' : "New",
      deduplicationLevel: 'CREATIVE',
      endDate: endDateTime.toUTC().toISO(),
      // // TODO modEndDate needs to be adnTimeShifter.timeShift(ctrl.model.endDate);
      modEndDate: endDateTime.toUTC().toISO(),
      startDate: startDateTime.toUTC().toISO(),
      // // TODO modStartDate needs to be adnTimeShifter.timeShift(ctrl.model.startDate);
      modStartDate: startDateTime.toUTC().toISO(),
      product: product,
      validationWarnings: [] as ValidationWarningMessage[],
    } as LineItem;
    if (networkData.optimiseCreativesFor !== undefined) {
      lineItem.optimiseCreativesFor = networkData.optimiseCreativesFor
    }
    if (product) {
      if (product.minimumCpm) {
        lineItem.bidSpecification.cpm = product.minimumCpm;
      } else if (product.minimumCpc) {
        lineItem.bidSpecification.cpc = product.minimumCpc;
      }
      lineItem.bidSpecification.cpmCostModel = 'PER_RENDERED';
      lineItem.bidSpecification.cpa = null;
    } else {
      lineItem.bidSpecification.cpm = undefined;
      lineItem.bidSpecification.cpmCostModel = 'PER_RENDERED';
      lineItem.bidSpecification.cpc = null;
      lineItem.bidSpecification.cpa = null;
    }
    return lineItem;
  }

  newCreative(product: Product | undefined, lineItemId: string, lineItemName: string): Creative {
    const layoutReference: LayoutReference | undefined =
      product?.layouts.length === 1 ? {id: product.layouts[0].id} as LayoutReference : undefined;
    return {
      createInterface: 'NET',
      copyrightStatus: this.configService.getNetworkData()!.hasCreativeCopyrightStatus ? 'ADVERTISER_UNCONFIRMED' : 'UNSPECIFIED',
      id: Uuid.generate(),
      name: this.creativeName(lineItemName),
      layout: layoutReference,
      targeting: this.newCreativeTargeting(),
      isNew: true,
      createTime: DateTime.now().toUTC().toISO(),
      layoutParameters: {},
      lineItem: {
        id: lineItemId,
      } as LineItem,
      width: product?.allowedDimensions[0][0],
      height: product?.allowedDimensions[0][1],
    } as Creative;
  }

  creativeName(lineItemName: string): string {
    return this.i18nFormatService.translate(
      'campaign.ad.defaultName',
      { 'campaignName': lineItemName}
    );
  }

  campaignFormFromLineItemReview(mediaTypeHelper: MediaTypeHelper, fb: NonNullableFormBuilder, network: Network, lineItemReview: LineItemReview, products: Product[], layouts: Layout[]): CampaignForm {
    const startDt = DateTime.fromISO(lineItemReview.lineItem.startDate);
    const endDt = DateTime.fromISO(lineItemReview.lineItem.endDate);
    const product = this.validatedProductOrDefault(lineItemReview.lineItem.product?.id, products);
    const campaignForm = fb.group({
      lineItemId: [lineItemReview.lineItem.id],
      productId: [product?.id as string, Validators.required],
      advertForms: fb.array(lineItemReview.creatives.map((review) =>
        this.advertFormFromCreativeReview(mediaTypeHelper, fb, product, review, layouts)
      )),
      name: [lineItemReview.lineItem.name, Validators.required],
      startDate: [{
        year: startDt.year,
        month: startDt.month as number,
        day: startDt.day as number
      }, Validators.required],
      startTime: [{hour: startDt.hour as number, minute: startDt.minute as number, second: 0}, Validators.required],
      endDate: [{year: endDt.year, month: endDt.month as number, day: endDt.day as number}, Validators.required],
      endTime: [{hour: endDt.hour as number, minute: endDt.minute as number, second: 0}, Validators.required],
      budgetAmount: [lineItemReview.lineItem.selfServiceBudget?.amount ?? undefined,
        [Validators.required,
          product && product.minimumBudget ? Validators.min(product.minimumBudget.amount) : Validators.nullValidator,
          product && product.maximumBudget ? Validators.max(product.maximumBudget.amount) : Validators.nullValidator
        ]
      ],
      budgetCurrency: [lineItemReview.lineItem.selfServiceBudget?.currency ?? product?.minimumBudget?.currency ?? product?.maximumBudget?.currency ?? network.defaultCurrency],
      smoothed: [lineItemReview.lineItem.smoothed],
    });
    if (product?.id !== lineItemReview.lineItem.product?.id) {
      // product must have been auto selected, or current product is no longer available
      campaignForm.controls.productId.markAsDirty();
    }
    return campaignForm;
  }

  validatedProductOrDefault(productId: string | undefined, products: Product[]): Product | undefined {
    const product = products.find((p) => p.id === productId);
    if (product) {
      return product;
    }
    if (products.length === 1) {
      return products[0];
    }
    return undefined;
  }

  advertFormFromCreativeReview(mediaTypeHelper: MediaTypeHelper, fb: NonNullableFormBuilder, product: Product | undefined,
                               creativeReview: CreativeReview, layouts: Layout[]): AdvertForm {
    const layout = this.validatedLayoutOrDefault(product, creativeReview.creative.layout?.id, layouts);
    const layoutComponents = this.newLayoutComponentFormArray(mediaTypeHelper, fb, layout, creativeReview.creative.layoutParameters);
    const originalCreativeSize = creativeReview.creative.width + 'x' + creativeReview.creative.height;
    const creativeSize = this.validatedCreativeSizeOrDefault(originalCreativeSize, product);
    const advertForm: AdvertForm = fb.group({
      ...this.copyrightStatusFormBuilder(creativeReview),
      creativeId: [creativeReview.creative.id],
      layoutComponents: layoutComponents,
      objectState: [creativeReview.creative.objectState],
      creativeSize: [creativeSize || "", Validators.required],
      showBorder: [false],
      targeting: this.targetingFormFromCreative(fb, product, creativeReview.creative.targeting),
      layoutId: layout?.id,
      creative: fb.control(creativeReview.creative),
      assets: fb.array(creativeReview.assets.map((asset) => fb.control(asset))),
    }) as AdvertForm;
    if (layout?.id !== creativeReview.creative.layout?.id) {
      // original layout is no longer available or a default layout was auto-selected
      advertForm.controls.layoutId.markAsDirty();
    }
    if (originalCreativeSize !== creativeSize) {
      // original size is no longer available, or a default size was auto-selected
      advertForm.controls.creativeSize.markAsDirty();
    }
    return advertForm;
  }

  copyrightStatusFormBuilder(creativeReview: CreativeReview): { copyrightStatus: Array<any> } {
    if (!this.configService.getNetworkData()?.hasCreativeCopyrightStatus) {
      return {} as { copyrightStatus: Array<any> };
    } else {
      return {copyrightStatus: [creativeReview.creative.copyrightStatus === 'ADVERTISER_CONFIRMED', Validators.requiredTrue]};
    }
  }

  validatedLayoutOrDefault(product: Product | undefined, layoutId: string | undefined, layouts: Layout[]): Layout | undefined {
    if (!product) return undefined;
    const layoutReference = product.layouts.find(layout => layout.id === layoutId);
    if (layoutReference) {
      return layouts.find(layout => layout.id === layoutReference.id);
    }
    if (product.layouts.length === 1) {
      return layouts.find(layout => layout.id === product.layouts[0].id);
    }
    return undefined;
  }

  newLayoutComponentFormArray(mediaTypeHelper: MediaTypeHelper, fb: NonNullableFormBuilder, layout?: Layout, layoutParameters?: LayoutParameters): FormArray<LayoutComponentForm> {
    return new FormArray<LayoutComponentForm>((layout?.layoutComponents || [])
      .filter((component) => this.isSupportedLayoutComponent(mediaTypeHelper, component))
      .map((component) => this.newLayoutComponentForm(fb, component, layoutParameters)));
  }

  validatedCreativeSizeOrDefault(creativeSize: string, product?: Product): string | undefined {
    if (!product) {
      return undefined;
    }
    const allowed = this.allowedCreativeSizes(product);
    if (allowed.includes(creativeSize)) {
      return creativeSize;
    }
    if (allowed.length === 1) {
      return allowed[0];
    }
    return undefined;
  }

  targetingFormFromCreative(fb: NonNullableFormBuilder, product: Product | undefined, targeting: Targeting): TargetingForm {
    return fb.group({
      deviceTargets: this.deviceTargetsFormArray(fb, product, targeting.deviceTargets),
      namedLocationTargets: this.namedLocationTargetsFormArray(fb, product, targeting.namedLocationTarget.locations),
      categoryTargets: this.categoryTargetsFormArray(fb, product, targeting.categoryTargets),
      siteTargets: this.siteTargetsFormArray(fb, product, targeting.siteTarget),
      domainNameTargets: this.domainNameTargetsFormArray(fb, product, targeting.domainNameTarget),
      userSegmentTargets: this.userSegmentTargetsFromArray(fb, product, targeting.userSegmentTargets),
    });
  }

  deviceTargetsFormArray(fb: NonNullableFormBuilder, product: Product | undefined, deviceTargets: DevicePropertyTarget[] | undefined): FormArray<FormControl<boolean>> {
    return fb.array(
      (product?.creativeTargeting?.deviceTargets || []).map((dpt) =>
        fb.control(_some(deviceTargets, dpt))
      )
    )
  }

  namedLocationTargetsFormArray(fb: NonNullableFormBuilder, product: Product | undefined, locations: NamedLocation[] | undefined): FormArray<FormControl<boolean>> {
    return fb.array(
      (product?.creativeTargeting.namedLocationTarget?.locations || []).map((nl) =>
        fb.control(_some(locations, nl))
      )
    )
  }

  categoryTargetsFormArray(fb: NonNullableFormBuilder, product: Product | undefined, categoryTargets: CategoryTarget[] | undefined): FormArray<FormControl<boolean>> {
    return fb.array(
      (product?.creativeTargeting.categoryTargets || []).map((categoryTarget) =>
        fb.control(_some(categoryTargets, categoryTarget))
      )
    )
  }

  domainNameTargetsFormArray(fb: NonNullableFormBuilder, product: Product | undefined, domainNameTarget: DomainNameTarget | undefined): FormArray<FormControl<boolean>> {
    return fb.array(
      (product?.creativeTargeting.domainNameTarget?.names || []).map((domainName) => {
        return fb.control(_indexOf(domainNameTarget?.names, domainName) > -1)
      })
    )
  }

  siteTargetsFormArray(fb: NonNullableFormBuilder, product: Product | undefined, siteTarget: SiteTarget | undefined): FormArray<FormControl<boolean>> {
    return fb.array(
      (product?.creativeTargeting.siteTarget?.sites || []).map((site) =>
        fb.control(_some(siteTarget?.sites, site))
      )
    )
  }

  userSegmentTargetsFromArray(fb: NonNullableFormBuilder, product: Product | undefined, userSegmentTargets: UserSegmentTarget[] | undefined): FormArray<FormControl<boolean>> {
    return fb.array(
      (product?.creativeTargeting.userSegmentTargets || []).map((target) =>
        fb.control(_some(userSegmentTargets, target))
      )
    )
  }

  newCreativeTargeting(): Targeting {
    return {
      deviceTargets: [] as DevicePropertyTarget[],
      namedLocationTarget: {
        locations: [],
        negated: false,
      } as NamedLocationTarget,
      categoryTargets: [] as CategoryTarget[],
      domainNameTarget: {names: [], negated: false} as DomainNameTarget,
      siteTarget: {
        sites: []
      } as SiteTarget,
      userSegmentTargets: [] as UserSegmentTarget[]
    };
  }

  isSupportedLayoutComponent(mediaTypeHelper: MediaTypeHelper, component: LayoutComponent): boolean {
    if (isAssetComponent(component)) {
      return mediaTypeHelper.getSupportedMediaTypes(component).length > 0;
    } else {
      return isChoiceComponent(component) || isTextComponent(component) || isUrlComponent(component);
    }
  }

  newLayoutComponentForm(fb: NonNullableFormBuilder, layoutComponent: LayoutComponent, layoutParameters?: LayoutParameters): LayoutComponentForm {
    let value = "";
    const validators: ValidatorFn[] = [];
    if (layoutComponent.required === 'MANDATORY') {
      validators.push(Validators.required);
    }
    if (isAssetComponent(layoutComponent)) {
      if (layoutParameters && layoutParameters[layoutComponent.tag]) {
        value = (layoutParameters[layoutComponent.tag] as ImageParameter).id;
      }
      return fb.group({
        componentValue: [value, validators],
        layoutComponent: fb.control(layoutComponent)
      });
    } else if (isUrlComponent(layoutComponent)) {
      if (layoutParameters && layoutParameters[layoutComponent.tag]) {
        value = layoutParameters[layoutComponent.tag] as string;
      }
      validators.push(urlValidator);
      return fb.group({
        componentValue: [value, validators],
        layoutComponent: fb.control(layoutComponent)
      });
    } else if (isTextComponent(layoutComponent)) {
      if (layoutParameters && layoutParameters[layoutComponent.tag]) {
        value = layoutParameters[layoutComponent.tag] as string;
      }
      validators.push(Validators.minLength(layoutComponent.minLength));
      validators.push(Validators.maxLength(layoutComponent.maxLength));
      return fb.group({
        componentValue: [value, validators],
        layoutComponent: fb.control(layoutComponent)
      });
    } else if (isChoiceComponent(layoutComponent)) {
      if (layoutParameters && layoutParameters[layoutComponent.tag]) {
        value = layoutParameters[layoutComponent.tag] as string;
      }
      return fb.group({
        componentValue: [value, validators],
        layoutComponent: fb.control(layoutComponent)
      });
    }
    throw "don't know how to handle layoutComponent";
  }

  allowedCreativeSizes(product: Product): string[] {
    return product.allowedDimensions.map((d) => d[0] + 'x' + d[1]);
  }

}
