<div [formGroup]='campaignForm' class='mb-3'>
  <label class='form-label' for='budgetInput'>{{ 'field.selfServiceBudget' | translate }}</label>
  <div class='mb-1 has-validation'>
    <div class='input-group' [ngClass]="{ 'is-invalid': isInvalid }">
      <input type='number' (keyup)="budgetUpdated()" style='max-width: 130px' id='budgetInput' class='form-control' formControlName='budgetAmount'
             [ngClass]="{ 'is-invalid': isInvalid }" data-lpignore='true'
             [attr.disabled]="hasRejectedAdverts ? '' : null">
      <span class='input-group-text' [innerHTML]='campaignForm.controls.budgetCurrency.value'></span>
    </div>
    <div [ngClass]="{ 'invalid-feedback' : isInvalid }" class='form-text'>
      <div *ngIf='!campaignForm.pristine'>
        <ng-container *ngFor='let err of campaignForm.controls.budgetAmount.errors | keyvalue'>
          <ng-container [ngSwitch]='err.key'>
            <span *ngSwitchCase="'min'">{{ 'validation.minPrice' | translate: {minValue: budgetMinValue} }}</span>
            <span *ngSwitchCase="'max'">{{ 'validation.maxPrice' | translate: {maxValue: budgetMaxValue} }}</span>
            <span *ngSwitchDefault>{{ 'validation.' + err.key | translate }}</span>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf='campaignForm.pristine'>
        <ng-container *ngFor='let validationWarning of budgetValidationWarnings'>
          <ng-container [ngSwitch]='validationWarning.code'>
            <div *ngSwitchCase="'validation.warning.lineItem.minimum.budget'">
              {{ 'validation.minPrice' | translate: {minValue: budgetMinValue} }}
            </div>
            <div *ngSwitchCase="'validation.warning.lineItem.maximum.budget'">
              {{ 'validation.maxPrice' | translate: {maxValue: budgetMaxValue} }}
            </div>
            <div *ngSwitchDefault>{{ validationWarning.text }}</div>
          </ng-container>
        </ng-container>
      </ng-container>
      <div *ngIf='expectedImpressions'>
        {{ 'campaign.expectedImpressions' | translate: {imps: expectedImpressions} }}
      </div>
      <div *ngIf='expectedClicks'>
        {{ 'campaign.expectedClicks' | translate: {clicks: expectedClicks} }}
      </div>
      <div *ngIf='hasRejectedAdverts'>
        <div>{{ 'campaign.adRejected.budgetUnchanged' | translate }}</div>
      </div>
    </div>
  </div>
</div>
