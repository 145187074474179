import {Component, Input, OnInit} from '@angular/core';
import {CampaignForm} from '@app/@core/advertising/campaign/campaign-form';
import {CampaignDraft} from "@app/@core/advertising/campaign/campaign-draft";

@Component({
  selector: 'app-ad-delivery-style[campaignDraft]',
  templateUrl: './ad-delivery-style.component.html',
  styleUrls: ['./ad-delivery-style.component.scss']
})
export class AdDeliveryStyleComponent implements OnInit {
  @Input() campaignDraft!: CampaignDraft;

  suffix: string | undefined;

  constructor() { }

  ngOnInit(): void {
    const amount = this.campaignDraft.lineItemReview.product?.minimumCpc?.amount || 0;
    if (amount > 0) {
      this.suffix = "Cpc";
    }
  }
}
