import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ValidationWarningMessage} from '@app/@core/@models/advertising/validation-warning-message.model';
import {NetworkData} from '@app/@core/@config/registered-urls-data';
import {ConfigService} from '@app/@core/@config/config.service';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';

@Component({
  selector: 'app-campaign-details[campaignDraft]',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss']
})
export class CampaignDetailsComponent implements OnInit, OnChanges {
  @Input() campaignDraft!: CampaignDraft;
  networkData: NetworkData;
  generalValidationWarnings: ValidationWarningMessage[] = [];
  startDateValidationWarnings: ValidationWarningMessage[] = [];
  endDateValidationWarnings: ValidationWarningMessage[] = [];

  private skipWarnings: string[] = [
    'validation.warning.lineItem.requires.budget',
    'validation.warning.lineItem.missing.impression.from.product',
    'validation.warning.lineItem.unapproved',
    'validation.warning.lineItem.must.have.objective',
    'validation.warning.lineItem.no.valid.creatives',
    'validation.warning.lineItem.minimum.budget',
    'validation.warning.lineItem.maximum.budget',
    'validation.warning.lineItem.not.active'
  ];

  constructor(private configService: ConfigService,
              public i18nFormatService: I18nFormatService) {
    this.networkData = this.configService.getNetworkData()!;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.generalValidationWarnings = this.campaignDraft.lineItemReview.lineItem.validationWarnings.filter(
      (message) =>
        this.skipWarnings.indexOf(message.code) < 0 &&
        !this.isStartDateValidationWarning(message) &&
        !this.isEndDateValidationWarning(message)
    );

    this.startDateValidationWarnings = this.campaignDraft.lineItemReview.lineItem.validationWarnings.filter(
      (message) => this.isStartDateValidationWarning(message)
    );

    this.endDateValidationWarnings = this.campaignDraft.lineItemReview.lineItem.validationWarnings.filter(
      (message) => this.isEndDateValidationWarning(message)
    );
  }

  private isStartDateValidationWarning(message: ValidationWarningMessage): boolean {
    return message.code.startsWith('validation.warning.lineItem.start.');
  }

  private isEndDateValidationWarning(message: ValidationWarningMessage): boolean {
    return message.code.startsWith('validation.warning.lineItem.end.');
  }

}
