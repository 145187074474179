import {CampaignForm} from '@app/@core/advertising/campaign/campaign-form';
import {Product} from '@app/@core/@models/design/product.model';
import {Network} from '@app/@core/@models/network/network.model';
import {CreativeReview, LineItemReview} from '@app/@core/@models/advertising/line-item-review.model';
import {Layout, LayoutReference} from '@app/@core/@models/advertising/layout/layout.model';
import {ProductSelectionData} from '@app/@core/@models/network/network-data.model';
import {AdvertDraft} from '@app/@core/advertising/campaign/advert/advert-draft';
import {EventEmitter} from '@angular/core';
import {NonNullableFormBuilder} from '@angular/forms';
import {MediaTypeHelper} from '@app/@core/design/media-type-helper.service';
import {LineItem} from '@app/@core/@models/advertising/lineitem.model';
import {DateTime} from 'luxon';
import _isFinite from 'lodash-es/isFinite';
import {AdvertForm} from '@app/@core/advertising/campaign/advert/advert-form';
import {assertNonNullable} from '@shared/utils/asserts';
import {Creative, ImageParameter, LayoutParameters} from '@app/@core/@models/advertising/creative.model';
import {Targeting} from '@app/@core/@models/targeting/targeting.model';
import {LayoutComponentForm} from '@app/@core/advertising/campaign/layout/layout-component-form';
import {AssetComponentForm} from '@app/@core/advertising/campaign/layout/asset-comoponent-form';
import {isAssetComponent} from '@app/@core/@models/advertising/layout/asset-component.model';
import {LayoutComponent} from '@app/@core/@models/advertising/layout/layout-component.model';
import {isChoiceComponent} from '@app/@core/@models/advertising/layout/choice-component.model';
import {ChoiceComponentForm} from '@app/@core/advertising/campaign/layout/choice-comoponent-form';
import {TextComponentForm} from '@app/@core/advertising/campaign/layout/text-component-form';
import {isTextComponent} from '@app/@core/@models/advertising/layout/text-component.model';
import {UrlComponentForm} from '@app/@core/advertising/campaign/layout/url-compnent-form';
import {isUrlComponent} from '@app/@core/@models/advertising/layout/url-component.model';
import {CampaignDraftFactory} from '@app/@core/advertising/campaign/campaign-draft-factory.service';
import {CampaignEvent} from '@app/@core/advertising/campaign/campaign-event';
import {ResultList} from '@app/@core/@models/common/result-list';
import {Coupon} from '@app/@core/@models/design/coupon.model';
import {ConfigService} from "@app/@core/@config/config.service";

/**
 * State of a campaign being edited in the UI.
 */
export class CampaignDraft {

  private updateResult = new EventEmitter<CampaignEvent>();
  advertDrafts: AdvertDraft[] = [];

  constructor(public campaignDraftFactory: CampaignDraftFactory,
              public fb: NonNullableFormBuilder,
              public configService: ConfigService,
              public mediaTypeHelper: MediaTypeHelper,
              public network: Network,
              public anon: boolean,
              public products: Product[],
              public layouts: Layout[],
              public lineItemReview: LineItemReview,
              public campaignForm: CampaignForm,
              public productSelectionData: ProductSelectionData | undefined,
              public signUpCoupons: ResultList<Coupon> | undefined) {
    for (let creativeForm of this.campaignForm.controls.advertForms.controls) {
      if (creativeForm.value.objectState !== 'HIDDEN') {
        this.advertDrafts.push(new AdvertDraft(this, creativeForm, this.advertDrafts.length + 1));
      }
    }
  }

  subscribeUpdate(next?: (value: CampaignEvent) => void) {
    this.updateResult.subscribe(next);
  }

  sendUpdate(update: CampaignEvent) {
    this.updateResult.next(update);
  }

  getProductId(): string | undefined {
    return this.campaignForm.controls.productId.value;
  }

  getProduct(): Product | undefined {
    const productId = this.getProductId();
    return this.products.find((p) => p.id === productId);
  }

  wasProductDeleted(): boolean {
    return !!this.campaignForm.value.productId && !this.getProduct();
  }

  productChanged(product: Product) {
    this.campaignProductChanged(this, product);
  }

  layoutsForSelectedProduct(pProduct?: Product): Layout[] {
    const product = pProduct || this.getProduct();
    if (product) {
      return this.layouts.filter(layout => product.layouts.find(l => l.id === layout.id));
    } else {
      return [];
    }
  }

  canSubmit(): boolean {
    return this.campaignForm.valid &&
      !this.wasProductDeleted() &&
      !this.anon &&
      this.lineItemReview.lineItem.userState === 'PROPOSED' &&
      this.lineItemReview.lineItem.objectState === 'ACTIVE' &&
      !this.lineItemReview.lineItem.isNew &&
      !this.advertDrafts.find(advertDraft => !advertDraft.isSelectedLayoutValid()) &&
      (!this.configService.getNetworkData()?.mandatoryCategories || !!this.advertDrafts.find(advertDraft => {
        return advertDraft.advertForm.controls.targeting.controls.categoryTargets.controls.find(control => {
          return control.value;
        });
      }));
  }

  doSave(successKey?: string) {
    const update = this.getLineItemReviewUpdate(this);
    this.updateResult.next({
      successKey: successKey,
      lineItemReviewUpdate: update,
      save: true
    });
  }

  onUndo() {
    this.updateResult.next({reloadLineItemReview: true}); // reload line item and reset the form
  }

  rejectedAdverts(): number[] {
    return this.advertDrafts
      .filter(advDraft => advDraft.creative.rejectedReason)
      .map(advDraft => advDraft.count);
  }

  public getLineItemUpdate(product: Product, campaignForm: CampaignForm): LineItem {
    const lineItemUpdate: LineItem = {
      bidSpecification: {
      },
      id: campaignForm.value.lineItemId,
      product: {
        id: campaignForm.value.productId,
      } as Product,
      smoothed: campaignForm.value.smoothed,
    } as LineItem;
    if (product.minimumCpm) {
      lineItemUpdate.bidSpecification.cpm = product.minimumCpm;
    } else if (product.minimumCpc) {
      lineItemUpdate.bidSpecification.cpc = product.minimumCpc;
    }
    if (campaignForm.controls.name.valid && campaignForm.controls.name.dirty) {
      lineItemUpdate.name = campaignForm.controls['name'].value;
    }
    if (campaignForm.controls.startDate.valid && campaignForm.controls.startTime.valid) {
      const startDateTime = DateTime.fromObject({...campaignForm.value.startDate, ...campaignForm.value.startTime})
      lineItemUpdate.startDate = startDateTime.toUTC().toISO();
    }
    if (campaignForm.controls.endDate.valid && campaignForm.controls.endTime.valid) {
      const startDateTime = DateTime.fromObject({...campaignForm.value.endDate, ...campaignForm.value.endTime})
      lineItemUpdate.endDate = startDateTime.toUTC().toISO();
    }
    if (campaignForm.controls['budgetAmount'].dirty) {
      if (_isFinite(campaignForm.controls.budgetAmount.value)) {
        lineItemUpdate.selfServiceBudget = {
          amount: Number(campaignForm.controls.budgetAmount.value),
          currency: campaignForm.value.budgetCurrency!,
        };
        if (product.minimumCpc?.amount) {
          lineItemUpdate.objectives = {
            CLICK: Math.ceil((1000 * lineItemUpdate.selfServiceBudget.amount) / product.minimumCpc.amount),
          };
        } else {
          lineItemUpdate.objectives = {
            RENDERED_IMPRESSION: Math.ceil((1000 * lineItemUpdate.selfServiceBudget.amount) / product.minimumCpm.amount),
          };
        }
      }
    }
    return lineItemUpdate;
  }

  getCreativeReview(campaignForm: CampaignForm, advertForm: AdvertForm, product?: Product): CreativeReview {
    assertNonNullable(product);
    return {
      assets: advertForm.value.assets!,
      creative: this.creativeFromCreativeForm(product, advertForm),
      layout: {id: advertForm.value.layoutId} as Layout
    }
  }

  numVisibleCreatives(campaignForm: CampaignForm): number {
    let numVisible = 0;
    for (let i = 0; i < campaignForm.controls.advertForms.length; i++) {
      const creativeControl = campaignForm.controls.advertForms.at(i).value;
      if (creativeControl.objectState !== 'HIDDEN') {
        numVisible++;
      }
    }
    return numVisible;
  }

  advertisementNumber(campaignForm: CampaignForm, advertForm: AdvertForm): number {
    let advertNumber = 0;
    for (let i = 0; i < campaignForm.controls.advertForms.length; i++) {
      const creativeControl = campaignForm.controls.advertForms.at(i).value;
      if (creativeControl.objectState !== 'HIDDEN') {
        advertNumber++;
      }
      if (advertForm.value.creativeId === creativeControl.creativeId) {
        return advertNumber;
      }
    }
    return -1;
  }

  /**
   * Set product, update lineItemReview which will trigger CampaignDraft re-load
   */
  campaignProductChanged(campaignDraft: CampaignDraft, product: Product) {
    if (!campaignDraft.lineItemReview.lineItem.product) {
      // first time a product was selected or new product selected after product deletion
      campaignDraft.campaignForm.controls.name.setValue(product.name + " Campaign");
      campaignDraft.campaignForm.controls.name.markAsDirty();
    }
    campaignDraft.campaignForm.controls.productId.setValue(product.id);
    campaignDraft.campaignForm.controls.productId.markAsDirty();
    campaignDraft.advertDrafts.forEach(advertDraft => advertDraft.productChanged(product));
    campaignDraft.sendUpdate({
      lineItemReviewUpdate: this.getLineItemReviewUpdate(campaignDraft),
    });
  }

  layoutChanged(advertDraft: AdvertDraft) {
    advertDraft.campaignDraft.sendUpdate({
      lineItemReviewUpdate: this.getLineItemReviewUpdate(advertDraft.campaignDraft)
    });
  }

  addAnotherAdvert(advertDraft: AdvertDraft) {
    const lineItemReviewUpdate = this.getLineItemReviewUpdate(advertDraft.campaignDraft);
    const creative = this.campaignDraftFactory.newCreative(
      undefined,
      this.campaignForm.value.lineItemId!,
      this.campaignForm.value.name!,
    );
    const product = advertDraft.campaignDraft.getProduct();
    if (product?.layouts.length === 1) {
      creative.layout = {id: product.layouts[0].id} as LayoutReference
    }
    lineItemReviewUpdate.creatives.push({
      assets: [],
      creative: creative,
      layout: undefined
    } as CreativeReview);
    advertDraft.campaignDraft.sendUpdate({
      lineItemReviewUpdate: lineItemReviewUpdate
    });
  }

  advertHidden(advertDraft: AdvertDraft) {
    advertDraft.campaignDraft.sendUpdate({
      lineItemReviewUpdate: this.getLineItemReviewUpdate(advertDraft.campaignDraft)
    });
  }

  getLineItemReviewUpdate(campaignDraft: CampaignDraft): LineItemReview {
    const product = campaignDraft.getProduct()
    assertNonNullable(product);
    const lineItemUpdate = this.getLineItemUpdate(product, campaignDraft.campaignForm);
    return {
      lineItem: lineItemUpdate,
      product,
      creatives: campaignDraft.campaignForm.controls.advertForms.controls.map(
        creativeForm => this.getCreativeReview(
          campaignDraft.campaignForm,
          creativeForm,
          product)
      )
    } as LineItemReview
  }

  creativeFromCreativeForm(product: Product, advertForm: AdvertForm): Creative {
    return {
      ...this.copyrightStatusFromForm(advertForm),
      id: advertForm.value.creativeId,
      name: this.campaignDraftFactory.creativeName(this.campaignForm.value.name || ''),
      layout: {id: advertForm.value.layoutId},
      layoutParameters: this.getLayoutParametersUpdate(advertForm.controls.layoutComponents.controls),
      lineItem: {id: this.campaignForm.value.lineItemId},
      objectState: advertForm.value.objectState,
      width: advertForm.value.creativeSize?.split('x')[0],
      height: advertForm.value.creativeSize?.split('x')[1],
      targeting: this.targetingFromCreativeForm(product, advertForm),
    } as Creative;
  }

  copyrightStatusFromForm(advertForm: AdvertForm): Creative {
    if (advertForm.value.copyrightStatus === undefined) {
      return {copyrightStatus: 'UNSPECIFIED'} as Creative;
    } else if (advertForm.value.copyrightStatus) {
      return {copyrightStatus: 'ADVERTISER_CONFIRMED'} as Creative;
    } else {
      return {copyrightStatus: 'ADVERTISER_UNCONFIRMED'} as Creative;
    }
  }

  targetingFromCreativeForm(product: Product, advertForm: AdvertForm): Targeting {
    const targeting = this.campaignDraftFactory.newCreativeTargeting();
    const creativeDeviceTargets = advertForm.value.targeting!.deviceTargets;
    if (creativeDeviceTargets) {
      for (let i = 0; i < creativeDeviceTargets.length; i++) {
        if (creativeDeviceTargets[i] && product?.creativeTargeting.deviceTargets) {
          targeting.deviceTargets.push(product.creativeTargeting.deviceTargets[i]);
        }
      }
    }
    const namedLocationTargetLocations = advertForm.value.targeting!.namedLocationTargets;
    if (namedLocationTargetLocations) {
      for (let i = 0; i < namedLocationTargetLocations.length; i++) {
        if (namedLocationTargetLocations[i] && product?.creativeTargeting.namedLocationTarget) {
          targeting.namedLocationTarget.locations.push(
            product.creativeTargeting.namedLocationTarget.locations[i]
          );
        }
      }
    }
    const categoryTargets = advertForm.value.targeting!.categoryTargets;
    if (categoryTargets) {
      for (let i = 0; i < categoryTargets.length; i++) {
        if (categoryTargets[i] && product?.creativeTargeting.categoryTargets) {
          targeting.categoryTargets.push(product.creativeTargeting.categoryTargets[i]);
        }
      }
    }
    const domainNameTargets = advertForm.value.targeting!.domainNameTargets;
    if (domainNameTargets) {
      for (let i = 0; i < domainNameTargets.length; i++) {
        if (domainNameTargets[i] && product?.creativeTargeting.domainNameTarget) {
          targeting.domainNameTarget.names.push(
            product.creativeTargeting.domainNameTarget.names[i]
          );
        }
      }
    }
    const siteTargets = advertForm.value.targeting!.siteTargets;
    if (siteTargets) {
      for (let i = 0; i < siteTargets.length; i++) {
        if (siteTargets[i] && product?.creativeTargeting.siteTarget) {
          targeting.siteTarget.sites.push(
            product.creativeTargeting.siteTarget.sites[i]
          );
        }
      }
    }
    const userSegmentTargets = advertForm.value.targeting!.userSegmentTargets;
    if (userSegmentTargets) {
      for (let i = 0; i < userSegmentTargets.length; i++) {
        if (userSegmentTargets[i] && product?.creativeTargeting.userSegmentTargets) {
          targeting.userSegmentTargets.push(
            product.creativeTargeting.userSegmentTargets[i]
          )
        }
      }
    }
    return targeting;
  }

  /**
   * Layout handling.
   */

  isAssetComponentForm(form: LayoutComponentForm): form is AssetComponentForm {
    return isAssetComponent(form.value.layoutComponent as LayoutComponent);
  }

  isChoiceComponentForm(form: LayoutComponentForm): form is ChoiceComponentForm {
    return isChoiceComponent(form.value.layoutComponent as LayoutComponent);
  }

  isTextComponentForm(form: LayoutComponentForm): form is TextComponentForm {
    return isTextComponent(form.value.layoutComponent as LayoutComponent);
  }

  isUrlComponentForm(form: LayoutComponentForm): form is UrlComponentForm {
    return isUrlComponent(form.value.layoutComponent as LayoutComponent);
  }

  getLayoutParametersUpdate(layoutComponentForms?: LayoutComponentForm[]): LayoutParameters {
    const layoutParameters = {} as LayoutParameters;
    if (layoutComponentForms) {
      layoutComponentForms.forEach(layoutComponentForm => {
        if (this.isAssetComponentForm(layoutComponentForm) && layoutComponentForm.value.componentValue) {
          layoutParameters[layoutComponentForm.value.layoutComponent!.tag] = {id: layoutComponentForm.value.componentValue} as ImageParameter;
        } else if (this.isUrlComponentForm(layoutComponentForm) && layoutComponentForm.value.componentValue != null) {
          layoutParameters[layoutComponentForm.value.layoutComponent!.tag] = layoutComponentForm.value.componentValue;
        } else if (this.isTextComponentForm(layoutComponentForm) && layoutComponentForm.value.componentValue != null) {
          layoutParameters[layoutComponentForm.value.layoutComponent!.tag] = layoutComponentForm.value.componentValue;
        } else if (this.isChoiceComponentForm(layoutComponentForm) && layoutComponentForm.value.componentValue != null) {
          layoutParameters[layoutComponentForm.value.layoutComponent!.tag] = layoutComponentForm.value.componentValue;
        }
      });
    }
    return layoutParameters;
  }

}
