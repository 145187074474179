import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  combineLatest,
  EMPTY,
  Observable,
  shareReplay,
  Subject,
  tap
} from 'rxjs';
import {CampaignCouponAvailable, Coupon} from '@app/@core/@models/design/coupon.model';
import {PaymentCalculation, PaymentCalculationInput} from '@app/@core/@models/network/payment.model';
import {PaymentResource} from '@app/@core/@rest/admin/payment-resource.service';
import {LineItemResource} from '@app/@core/@rest/advertising/line-item-resource.service';
import {CouponResource} from '@app/@core/@rest/design/coupons-resource.service';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import {Money} from '@app/@core/@models/network/money.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {CampaignEvent} from '@app/@core/advertising/campaign/campaign-event';

interface PaymentCalculationData {
  campaignCouponAvailable: CampaignCouponAvailable,
  paymentCalculation: PaymentCalculation
  coupon?: Coupon
}

@Component({
  selector: 'app-submit-payment[campaignDraft][isSubmitting][submitResult]',
  templateUrl: './submit-payment.component.html',
  styleUrls: ['./submit-payment.component.scss']
})
export class SubmitPaymentComponent implements OnInit, OnChanges {
  @Input() campaignDraft!: CampaignDraft;
  @Output() isSubmitting = new EventEmitter<boolean>();
  @Output() readonly submitResult = new EventEmitter<CampaignEvent>();
  readonly coupon$ = new Subject<Coupon | undefined>();
  paymentCalculationData$!: Observable<PaymentCalculationData>;
  errorKey?: string;

  constructor(private paymentResource: PaymentResource,
              private lineItemResource: LineItemResource,
              private couponResource: CouponResource,
              public i18nFormatService: I18nFormatService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const campaignCouponAvailable$ = this.getCampaignCouponAvailable(this.campaignDraft.getProductId()!);
    this.updateCoupon(campaignCouponAvailable$);
    this.paymentCalculationData$ = this.initPaymentCalculationProcessing(campaignCouponAvailable$);
  }

  handleCouponDiscount(event: [Coupon, PaymentCalculation] | undefined) {
    if (event) {
      this.coupon$.next(event[0]);
    } else {
      this.coupon$.next(undefined);
    }
  }

  getChargeAmount(paymentCalculation: PaymentCalculation): Money {
    if (paymentCalculation.amountWithDiscountApplied) {
      return paymentCalculation.amountWithDiscountApplied;
    } else {
      return this.campaignDraft.lineItemReview.lineItem.selfServiceBudget!;
    }
  }

  getBudgetedImpressions(): number {
    const product = this.campaignDraft.getProduct()!;
    return (1000 * this.campaignDraft.lineItemReview.lineItem.selfServiceBudget!.amount) / (product.minimumCpm?.amount || product.minimumCpc?.amount);
  }

  private updateCoupon(campaignCouponAvailable$: Observable<CampaignCouponAvailable>) {
    campaignCouponAvailable$.pipe(
      tap((campaignCouponAvailable) => {
        if (!campaignCouponAvailable.hasCoupon) {
          this.coupon$.next(undefined); // campaign coupon not available
        }
      }),
      switchMap(() => this.couponResource.signupCoupon()),
      tap((singUpCouponResponse) => {
        if (singUpCouponResponse.totalCount > 0) {
          this.coupon$.next(singUpCouponResponse.results[0]); // campaign coupon
        } else {
          this.coupon$.next(undefined); // campaign coupon not available
        }
      }),
      catchError((error) => {
        this.errorKey = error.error?.code || error.statusText || error;
        this.coupon$.next(undefined); // campaign coupon not available
        return EMPTY;
      })
    ).subscribe();
  }

  private getCampaignCouponAvailable(productId: string) {
    return this.couponResource.getCampaignCouponAvailable(productId)
      .pipe(shareReplay(1));
  }

  private getPaymentCalculationInput(maybeCoupon: Coupon | undefined): PaymentCalculationInput {
    const paymentCalculationInput = {
      amount: this.campaignDraft.lineItemReview.lineItem.selfServiceBudget,
      product: this.campaignDraft.lineItemReview.lineItem.product!.id,
    } as PaymentCalculationInput;
    if (maybeCoupon) {
      paymentCalculationInput.coupon = maybeCoupon;
    }
    return paymentCalculationInput;
  }

  private initPaymentCalculationProcessing(campaignCouponAvailable$: Observable<CampaignCouponAvailable>): Observable<PaymentCalculationData> {
    return combineLatest(
      [campaignCouponAvailable$, this.coupon$]
    ).pipe(
      switchMap(([campaignCouponAvailable, coupon]) =>
        this.paymentResource.calculatePost(this.getPaymentCalculationInput(coupon)).pipe(
          catchError((error) => {
            this.errorKey = error.error?.code || error.statusText || error;
            return EMPTY;
          }),
          map(paymentCalculation => ({coupon, paymentCalculation, campaignCouponAvailable}))
        )
      ),
    );
  }

}
