export const COMPANY_TRANSLATIONS_EN = {
  adweb: {
    title: 'AdWeb Advertising Portal',
    frontPage: `
      <h1>AdWeb Advertising Portal</h1>
      <ul>
        <li>Make your mark!</li>
        <li>Χαμηλές τιμές</li>
        <li>Καλύτερη ευκαιρία για διαφήμιση</li>
      </ul>
      <p>This is the chance of a lifetime.</p>
    `
  },
  bm: {
    pageTitle: 'Brand Mammoth Trader Login Portal',
    frontPage: `
      <h1>Brand Mammoth</h1>
      <h2>Trader Login Portal</h2>
    `
  },
  ipl: {
    pageTitle: 'IP DIGITAL',
    emailFooter: '<strong>selfservice@ipl.lu</strong>',
    frontPage: `
    <div style="text-align: left; padding: 10px">
        <p><a href="https://www.ipl.lu/digital/"><img src="https://regalia.adnuntius.com/label/ipl/logo-ipl.png" width="200"></a></p>
      </div>
      <ol>
        <li>Create your campaign</li>
        <li>Follow the diffusion</li>
        <li>Generate your diffusion report</li>
      </ol>
    `
  },
  gamma: {
    pageTitle: 'Gamma Advertising Portal',
    emailFooter: 'Contact us at <strong>info@gammassp.com</strong>',
    frontPage: `
      <div style="text-align: center">
        <p><a href="http://gammassp.com"><img src="https://regalia.adnuntius.com/label/gamma/gamma-front-page-logo.png" width="200"></a></p>
      </div>
      <h2>Advertising Portal</h2>
      <ol>
        <li>Create your account</li>
        <li>Set your budget</li>
        <li>Create your ad</li>
        <li>Start your campaign</li>
      </ol>
      <p>Target audiences across our ecosystem of over 1700 local publishers in Southeast Asia and create dynamic cross-screen campaigns,
        reaching more than 400+ million unique users.</p>
      <p class="small">Indonesia, Malaysia, Myanmar, Phillipines, Thailand, Vietnam, Singapore</p>
    `
  },
  ptimd: {
    pageTitle: 'Tempo Media Group Advertising Portal',
    emailFooter: 'Contact us at <strong>pasangiklan@tempo.co.id</strong>',
    frontPage: `
      <img src="https://regalia.adnuntius.com/label/pt/logo-front-page-network.png">
      <p style="position: relative; top: -20px">Saat ini beriklan online sangatlah mudah. Hanya dengan Upload, Bayar, dan Iklan Anda akan tayang di jaringan <strong>TEMPO NETWORK</strong>.</p>
    `
  },
  russ: {
    pageTitle: 'Russmedia Advertising Portal',
    emailFooter: 'Contact us at <strong>ads@russmedia.com</strong>',
    frontPage: `
      <div>
        <p><a href="http://russmedia.com"><img src="https://regalia.adnuntius.com/label/russmedia/201209_Russmedia_logo_200x40.png" width="200"></a></p>
      </div>
      <h2>Advertising portal</h2>
      <ol>
        <li>Create your account</li>
        <li>Set your budget</li>
        <li>Create your ad</li>
        <li>Start your campaign</li>
      </ol>
      <p>Reach your target group precisely across the entire Russmedia network. From <a href="http://vol.at">VOL.AT</a> through country indicators to <a href="http://vienna.at">VIENNA.AT</a>. Over 70% of Vorarlberg residents or over 4 million Austrians are waiting for your message.</p>
    `
  },
  fmy0: {
    campaign: {
      ad: {
        create: `<a class="headingLink" target="_blank" href="https://regalia.adnuntius.com/label/reflex/Whitepaper-TBE-neu.pdf">Instructions for designing the advertising material</a> Create Your Advertisement`
      },
    },
    emailFooter: 'Contact us at <strong>support@reflex-media.net</strong>.',
    frontPage: `
      <div>
        <p><img width="100%" src="https://regalia.adnuntius.com/label/reflex/reflex-logo-2.png"></p>
      </div>
      <h3>Self-service advertising</h3>
      <p>The ad self-booking tool that brings SMEs and the media together.</p>
      <ul>
        <li>Create your account</li>
        <li>Set your budget</li>
        <li>Create your ad</li>
        <li>Start your campaign</li>
      </ul>
    `
  },
  kf2z: {
    emailFooter: 'Contact us at <strong>support@adrevmedia.net</strong>',
    frontPage: `
      <div>
        <p style="text-align:center"><img src="https://regalia.adnuntius.com/label/adrev/adrev-logo-1.png" width="50%"></p>
      </div>
      <h3>AdRev</h3>
      <ul><li>Log in or start without logging in </li>
      <li>Select your desired form of advertising </li>
      <li>Create your ad </li>
      <li>Set your budget </li>
      <li>Start your campaign </li>
      </ul>
    `
  },
  v8t7: {
    campaign: {
      campaignInfo: `<p class="importantHighlight"><strong>Call +34 615 335 382 if you need assistance</strong></p>`,
      campaignSubInfo: `<p class="importantHighlight"><strong>Call +34 615 335 382 if you need assistance</strong></p>`
    },
    location: {
      Spain: "<strong>Spain</strong>"
    },
    frontPage: `
      <div><img src="https://regalia.adnuntius.com/label/confi/confi-logo.png" width="100%"></div>
      <h2 style="font-size: 18px">Advertising Self-Service</h2>
      <h3>4 easy steps</h3>
      <ol>
      <li>Login to your account</li>
      <li>Choose your budget</li>
      <li>Define your creative</li>
      <li>Launch your campaign</li>
      </ol>
      <p class="form-text small">El Confidencial Digital is the website of informed people who want to be more informed.</p>
      <p class="importantHighlight"><strong>Call +34 615 335 382 if you need assistance</strong></p>
    `,
    emailFooter: 'Contact us at <strong>cliente@elconfidencialdigital.es</strong>'
  },
  vpg3: {
    emailFooter: 'Contact us at <strong>onlinebuchung@az-muenchen.de</strong>',
    frontPage: `
      <div>
        <p><a href="https://www.abendzeitung-muenchen.de/"><img src="https://regalia.adnuntius.com/label/az/image.png" width="100%"></a></p>
      </div>
      <h3>Digital advertising portal - your advertisement on abendzeitung-muenchen.de </h3>
      <ul><li>Log in or start without logging in </li>
      <li>Select your desired form of advertising </li>
      <li>Create your ad </li>
      <li>Set your budget </li>
      <li>Start your campaign </li>
      </ul>
      <p>You can reach your local target group with the evening newspaper Digital.</p>
      <p>We will place your ad in the editorial environment of your city district or in the "Eating and Drinking" section</p>
      <p>Around 1 million people in Munich visit the evening newspaper's news portal regularly and look forward to your advertising message.</p>
    `
  },
  nkjm: {
    frontPageTopContent: `
        <div style="position:absolute;top: 50px; left: 50px;"><img src="https://assets.adnuntius.com/ZxrPk71X0QRHz9VjoaGyubjZyPRrXl8CMd0SZJMwy7c.png"></div>
    `,
    frontPage: `
      <h2 style="font-size: 45px">The One-stop Shop to the French Media</h2>
      <p><img src="https://www.mediasquare.fr/wp-content/themes/mediasquare/assets/point-gris-little.svg" style="margin: 10px 0;"></p>
      <p style="font-size: 16px; color: #555;margin-bottom: 60px">MediaSquare is the union of all the French media on offer in one single digital advertising market place, allowing you to reach more than 44 million individuals each month.</p>
    `
  },
  l16s: {
    frontPageTopContent: `
        <div style="position:absolute;top: 50px; left: 50px;"><img style="width: 200px" src="https://assets.adnuntius.com/cfadbe/rgx808j1wklvt51qj3kjcxgw1sp6dwzjfx7bml78jsvkg75dg8.png"></div>
    `,
    frontPage: `
      <h2 style="font-size: 35px">The One-stop Shop to Swiss Media</h2>
      <p><img src="https://www.mediasquare.fr/wp-content/themes/mediasquare/assets/point-gris-little.svg" style="margin: 10px 0;"></p>
      <p style="font-size: 16px; color: #555;margin-bottom: 60px">One ID grants you access to the best of Swiss mediar in one single digital advertising marketplace.</p>
    `
  },
  ais: {
    frontPage: `
      <div style="text-align:center">
        <img src="https://regalia.adnuntius.com/label/ais/Logo.png">
      </div>
      <h3 style="text-align: center; margin: 0">THAN ADS MANAGER</h3>
    `
  },
  '1v9y': {
    emailFooter: 'Contact us at <strong>werbelokal@mopo.de</strong>.',
    report: {
      noReportExample: 'An <a href="https://assets.adnuntius.com/70vN3w68p7-Tmnc0jGHQnedDOkEiA7FwIheigltPwCI.html" target="_blank">example report is available</a> to demonstrate what yours will look like once your campaign ends.'
    },
    signIn: {
      condition: `Consent to further data processing in Hamburger Morgenpost's CRM`,
      conditionError: 'You must agree to our conditions before you can register'
    },
    campaign: {
      filter: {
        preMessage: `
          <div class="row postMessageChoices">
            <div class="col-md-6">
              <p><strong>Werbebotschaft as Bild</strong></p>
              <img src="https://regalia.adnuntius.com/label/mopo/mopo_image_preview-bild.png">
            </div>
            <div class="col-md-6">
              <p><strong>Werbebotschaft as Bild-Text</strong></p>
              <img src="https://regalia.adnuntius.com/label/mopo/mopo_native_preview-bild-text.png">
            </div>
          </div>
        `
      },
      product: {
        examples: `
          <div class="container">
            <div class="col-md-6">
                <h4>Image-Based Advertising</h4>
                <p><img src="https://regalia.adnuntius.com/label/mopo/image-demo.jpg"></p>
            </div>
            <div class="col-md-6">
                <h4>Native Advertising</h4>
                <p><img src="https://regalia.adnuntius.com/label/mopo/native.jpg"></p>
            </div>
          </div>
        `
      }
    },
    frontPage: `
      <div>
        <p><img src="https://regalia.adnuntius.com/label/mopo/MOPO-logo-there.png" width="100%"></p>
      </div>
      <h3>The MOPO ADVERTISING SPACE</h3>
      <ol>
        <li>Create your account</li>
        <li>Set your budget</li>
        <li>Easily create your own ad</li>
        <li>Start your campaign</li>
      </ol>
      <p>Reach your customers here in Hamburg and the surrounding area accurately on <a target="_blank" href="https://mopo.de"> mopo.de </a>: With 24.7 million visits and 6 , 42 million unique users, we are THE advertising platform in the north!</p>
    `
  },
  y5rx: {
    frontPage: `
      <div>
        <p style="text-align: center"><img src="https://regalia.adnuntius.com/label/summit/summit-media-large.png" width="25%"></p>
      </div>
      <h3>Self-Serve Advertising Platform</h3>
      <p>Run your campaigns in the leading digital lifestyle network of the Philippines. Our unmatched access to and influence among specific audiences let you connect with exactly the market you aim to attract.</p>
      <p>For our soft launch, you can set up display ads that will run on <a href="https://spot.ph">Spot.ph</a>, a daily destination for discovering what's trending, what's worth trying, and what's worth doing in and around Metro Manila.</p>
    `
  },
  qlzz: {
    campaign: {
      ad: {
        create: `<a class="headingLink" target="_blank" href="https://regalia.adnuntius.com/label/atten/leitfaden.pdf">Instructions for designing the advertising material</a> Create Your Advertisement`
      },
    },
    frontPage: `
      <div>
        <p><img src="https://regalia.adnuntius.com/label/atten/menu.png" width="50%"></p>
      </div>
      <h3>Digital advertising portal - your ad on idowa.de</h3>
      <ul>
        <li>Log in or start without logging in</li>
        <li>Select your desired form of advertising</li>
        <li>Define your regional target group</li>
        <li>Create your ad</li>
        <li>Set your budget</li>
        <li>Start your campaign</li>
      </ul>
      <p>We will place your ad in the editorial environment of the start page.</p>
      <p>Benefit from our high reach and address the desired customers from the region!</p>
      <p><img src="https://regalia.adnuntius.com/label/atten/atten-smaller-logo.png" width="50%"></p>
    `,
    emailFooter: 'Contact us at <strong>portale@idowa.de</strong>.',
  },
  burda: {
    campaign: {
      ad: {
        stretched: ''
      }
    },
    frontPage: `
      <div>
        <p style="text-align: center"><img src="https://regalia.adnuntius.com/label/burda/front-page-logo.png" style="max-width: 250px"></p>
      </div>
      <h3>Advertise on Nebenan.de</h3>
      <ol>
        <li><strong>High quality environment</strong><br>Only with us are users address verified.</li>
        <li><strong>New touch point</strong><br>Only with us you are in the limelight of lively neighborhoods.</li>
        <li><strong>Good performance</strong><br>Only with us does the response come from a permanently high level of activity.</li>
        <li><strong>High credibility</strong><br>Only with us advertising is a matter for the heart.</li>
        <li><strong>Local Expertise</strong><br>Only with us, neighborhood experts lead you to success.</li>
      </ol>
    `,
    emailFooter: 'Contact us at: <strong>kampagnen@nebenan.de</strong>',
  },
  f5gc: {
    campaign: {
      filter: {
        preMessage: '',
        message: ''
      }
    },
    frontPage: `
      <div>
        <p style="text-align: center"><img src="https://regalia.adnuntius.com/label/foyster/fm.png" width="50%"></p>
      </div>
      <p><strong>Hello and welcome to the Foyster Media self-service advertising platform.</strong></p>
      <p>Here you can buy display media and sponsored content with 4 simple steps:</p>
      <ol>
        <li>Sign Up</li>
        <li>Upload your advertising</li>
        <li>Set your budget</li>
        <li>You're ready to set your advertising live on <a href="http://womenlovetech.com">womenlovetech.com</a> or <a href="http://thecarousel.com">thecarousel.com</a>.</li>
      </ol>
      <p>Sign in and let's get started on your ad campaign!</p>
    `
  },
  nxts: {
    frontPage: `
      <div>
        <p style="text-align: center"><img src="https://regalia.adnuntius.com/label/epp/eppsteiner_logo.jpg" width="75%"></p>
      </div>
      <h3>Digital Advertising Portal - Your Advertising on eppsteiner-zeitung.de</h3>
      <ul>
        <li>Log in or start without logging in</li>
        <li>Select your desired form of advertising</li>
        <li>Create your ad</li>
        <li>Set your budget</li>
        <li>Start your campaign</li>
      </ul>
      <p>With Eppsteiner Zeitung digital you can reach exactly your local target group.</p>
      <p>We place your ad in the editorial environment. Our users visit the Eppsteiner Zeitung portal regularly and look forward to your advertising message.</p>
    `
  },
  d966: {
    field: {
      vatId: 'TIN / Business Registration'
    },
    frontPage: `
      <div>
        <p style="text-align: center"><img src="https://regalia.adnuntius.com/label/philstar/logo.png"></p>
      </div>
      <h3>Portal for Self-Advertising</h3>
      <p>Philstar.com is one of the country's leading and most recognized digital brands in journalism. It is a top destination for a broad range of content, be it social, political, business, sports, entertainment or lifestyle news.</p>
      <p>For our soft launch, you can run display ad campaigns under our <a href="https://www.philstar.com/headlines" target="_blank">Headlines</a> and <a href="https://www.philstar.com/entertainment" target="_blank">Entertainment</a> sections.</p>
    `
  },
  zqjq: {
    frontPage: `
      <h3>Portal for Self-Service Advertising</h3>
      <p>Baseoilnews.com provides an online self-service advertising portal for the whole lubricants food-chain, from base oil producers to lubricant blenders and lube consumers.</p>
      <p>Sign up here to access the portal</p>
    `
  },
  svt3: {
    frontPage: `
      <div style="color: white">
        <h3 style="font-size: 34px">Our Media self service</h3>
        <div style="font-size: 20px;">
          <div style="margin-left: 10px">
            <div><strong style="color: #ffc15b">01</strong> Create your account</div>
            <div><strong style="color: #ffc15b">02</strong> Set your budget</div>
            <div><strong style="color: #ffc15b">03</strong> Easily build your own ad</div>
            <p><strong style="color: #ffc15b">04</strong> Start your campaign</p>
          </div>
          <p><em>The Our Media portfolio reaches nearly 20 million page views a month.</em></p>
        </div>
      </div>
    `
  },
  "67qx": {
    frontPage: `
      <h3>Portal for Immediate Media</h3>
      <ol>
        <li>Create your account</li>
        <li>Set your budget</li>
        <li>Easily create your own ad</li>
        <li>Start your campaign</li>
      </ol>
      <p>The Immediate Media portfolio reaches over 130 million page views a month.</p>
    `
  },
  try_opt: {
    field: {
      vatId: 'Organization Number'
    },
    emailFooter: '<strong>support@calt.no</strong>',
    frontPage: `
      <div>
        <p><img style="max-width: 50%" src="https://regalia.adnuntius.com/label/calt/calt-black.png"></p>
      </div>
      <p>Nå kan alle annonsere hos mer enn 200 norske kvalitetspublisister – enkelt!</p>
      <ol>
      <li>Opprett konto – det er gratis og fort gjort.</li>
      <li>Skap din annonse og målrett den mot ditt publikum.</li>
      <li>Betal med kredittkort, og få oppmerksomhet!</li>
      </ol>
    `
  },
  tkr5: {
    frontPage: `
      <div>
        <p style="text-align: center"><img style="max-width: 95%" src="https://regalia.adnuntius.com/label/fjell-ljom/logo-smaller.jpg"></p>
      </div>
      <ol style="font-size: 30px;">
        <li>Skap din konto</li>
        <li>Lag en annonse</li>
        <li>Bestem budsjett</li>
        <li>Betal</li>
        <li>Kampanjene er klar</li>
      </ol>
    `
  },
};
